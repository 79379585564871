<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M14.3,28c-1,0-1.8-0.3-2.3-0.9c-0.5-0.6-0.8-1.3-0.8-2.1c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.6-0.9,1.1-1.2
	c0.4-0.3,0.8-0.6,1.1-1.1c0.3-0.4,0.5-0.9,0.6-1.4l-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l-2.8,1c-0.3,0.1-0.7,0.2-1,0.3
	c-0.3,0.1-0.7,0.1-1,0.1c-1.3,0-2.4-0.6-3.3-1.7S4,16.4,4,14.3c0-1,0.3-1.8,0.9-2.3s1.3-0.8,2.1-0.8c0.5,0,1,0.1,1.5,0.3
	c0.5,0.2,0.9,0.6,1.2,1.1c0.3,0.4,0.6,0.8,1.1,1.1s0.9,0.5,1.4,0.6l0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3l-1-2.8c-0.1-0.3-0.2-0.7-0.3-1
	s-0.1-0.6-0.1-1c0-1.3,0.5-2.4,1.6-3.4S15.6,4,17.7,4c1,0,1.8,0.3,2.3,0.9c0.5,0.6,0.8,1.3,0.8,2.1c0,0.5-0.1,1-0.3,1.5
	c-0.2,0.5-0.6,0.9-1.1,1.2c-0.4,0.3-0.8,0.6-1.1,1.1s-0.5,0.9-0.6,1.4l0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2l2.8-1c0.3-0.1,0.7-0.2,1-0.3
	c0.3-0.1,0.6-0.1,1-0.1c1.6,0,2.8,0.7,3.6,2S28,16,28,17.7c0,1-0.3,1.8-1,2.3c-0.6,0.5-1.4,0.8-2.1,0.8c-0.5,0-1-0.1-1.5-0.3
	c-0.5-0.2-0.9-0.6-1.2-1.1c-0.3-0.4-0.6-0.8-1.1-1.1c-0.4-0.3-0.9-0.5-1.4-0.6l-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3l1,2.8
	c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.1,0.6,0.1,0.9c0,1.3-0.5,2.4-1.6,3.5C18.1,27.5,16.5,28,14.3,28 M16,17.8c0.5,0,0.9-0.2,1.3-0.5
	s0.5-0.8,0.5-1.3c0-0.5-0.2-0.9-0.5-1.3s-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5s-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3
	C15.1,17.6,15.5,17.8,16,17.8 M14.6,12c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.2-0.8,0.5-1.6,0.9-2.3c0.5-0.7,1-1.3,1.8-1.8
	c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.8,0-1.6,0.2-2.6,0.5
	s-1.5,1-1.5,1.9c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.1,0.3,0.1,0.4L14.6,12z M8.8,18.4c0.3,0,0.6-0.1,1-0.2l2.2-0.8
	c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.8-0.2-1.6-0.5-2.3-0.9c-0.7-0.5-1.3-1-1.8-1.8c-0.1-0.1-0.2-0.2-0.3-0.2
	c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.5c0,1.1,0.2,2,0.6,2.8S8,18.4,8.8,18.4 M14.3,25.6
	c0.9,0,1.9-0.2,2.8-0.6c0.9-0.4,1.3-1,1.3-2c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4L17.4,20c-0.1,0-0.2,0.1-0.4,0.1
	c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.8-0.5,1.6-0.9,2.3c-0.5,0.7-1,1.3-1.8,1.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.3-0.1,0.4
	c0,0.2,0.1,0.3,0.2,0.4C13.9,25.5,14.1,25.6,14.3,25.6 M24.9,18.4c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.2-0.3,0.2-0.6
	c0-0.8-0.2-1.6-0.5-2.6c-0.3-1-1-1.5-2-1.5c-0.2,0-0.3,0-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.1L20,14.6c0,0.1,0.1,0.2,0.1,0.4
	c0,0.1,0.1,0.3,0.1,0.4c0.8,0.2,1.6,0.5,2.3,0.9c0.7,0.5,1.3,1,1.8,1.8c0.1,0.1,0.1,0.2,0.3,0.2C24.7,18.4,24.8,18.4,24.9,18.4"
    />
  </svg>
</template>
<script setup></script>
