<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M15.1,28l7-14.6H17V4L9.9,18.6H15L15.1,28L15.1,28z" />
  </svg>
</template>
<script setup></script>
