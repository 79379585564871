<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M16,4C9.6,9.5,6.4,14.2,6.4,18.2c0,6,4.6,9.8,9.6,9.8s9.6-3.9,9.6-9.8C25.6,14.2,22.4,9.5,16,4 M16,25.6
	c-4,0-7.2-3.1-7.2-7.4c0-2.8,2.3-6.5,7.2-11c4.9,4.4,7.2,8.1,7.2,11C23.2,22.5,20,25.6,16,25.6 M11,18.4c0.4,0,0.8,0.3,0.9,0.7
	c0.5,2.7,2.7,3.6,4.4,3.4c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-2.6,0.2-5.5-1.3-6.2-4.9c-0.1-0.5,0.3-1,0.7-1
	C10.9,18.4,10.9,18.4,11,18.4"
    />
  </svg>
</template>
<script setup></script>
