/* eslint-disable no-unused-vars */
import { fabric } from "fabric";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import { line, point } from "@flatten-js/core";
import {
  drawObjectMeasures,
  positionAllInputs,
  removeMeasures,
} from "@/script/measureUtils";
import {
  getMouseProjectionOnWall,
  getWallFlattenLine,
  calcDistance,
  getWallCoords,
  transformPointerToPolygonCoords,
} from "@/script/generalUtils";
import { initScale, wallColor } from "@/script/canvasUtils";
import { removeCursor } from "@/script/wallsUtils";
import WaterIconImg from "@/assets/water.svg";
import GasIconImg from "@/assets/gas.svg";
import FanIconImg from "@/assets/fan.svg";
import ElectricityIconImg from "@/assets/electricity.svg";
import SewageIconImg from "@/assets/sewage.svg";

import WaterIcon from "@/components/icons/WaterIcon.vue";
import GasIcon from "@/components/icons/GasIcon.vue";
import FanIcon from "@/components/icons/FanIcon.vue";
import ElectricityIcon from "@/components/icons/ElectricityIcon.vue";
import SewageIcon from "@/components/icons/SewageIcon.vue";

const stickAllowance = 200;
//Дефолтные значения

const openingsDefault = {
  window: {
    color: "lightblue",
    width: 1000,
    distanceFromFloor: 850,
    openingHeight: 1450,

    single: false,
    opensInside: true,
    opensToRight: false,
    frameType: 1, //0 - глухое, 1 - обе открываются, 2 - левая глухая, 3 - правая глухая
    label: "Окно",
  },
  door: {
    color: "Snow",
    width: 800,
    distanceFromFloor: 0,
    openingHeight: 2000,
    single: true,
    opensInside: false,
    opensToRight: false,
    frameType: undefined,
    label: "Дверь",
  },
  arch: {
    color: "Snow",
    width: 800,
    openingHeight: 2200,
    distanceFromFloor: 0,
    frameType: undefined,
    single: false,
    opensInside: false,
    opensToRight: false,
    label: "Арка",
  },
};
const communicationsDefault = {
  water: {
    icon: WaterIconImg,
    color: "#2b92f3",
    stroke: "#FFFFFF",
    component: WaterIcon,
    label: "Вода",
  },
  gas: {
    icon: GasIconImg,
    color: "#62D3F9",
    stroke: "#FFFFFF",
    component: GasIcon,

    label: "Газ",
  },
  electricity: {
    icon: ElectricityIconImg,
    color: "#F26322",
    stroke: "#FFFFFF",
    component: ElectricityIcon,

    label: "Электропроводка",
  },
  airduct: {
    icon: FanIconImg,
    color: "#FFFFFF",
    stroke: "#62D3F9",
    component: FanIcon,

    label: "Вентиляция",
  },
  sewage: {
    icon: SewageIconImg,
    color: "#92ADC8",
    stroke: "#FFFFFF",
    component: SewageIcon,

    label: "Канализация",
  },
};

//
function addOpening(openingType) {
  const canvasDataStore = useCanvasDataStore(),
    { canvasRef, openingsRef } = storeToRefs(canvasDataStore);
  // бросаем проем +- рандомно где-то в районе центра
  const min = 0.3 * canvasRef.value.width;
  const max = 0.6 * canvasRef.value.width;
  const left = Math.floor(Math.random() * (max - min + 1)) + min;
  const top = Math.floor(Math.random() * (max - min + 1)) + min;
  const openingObj = makeOpening({
    widthClean: openingsDefault[openingType].width,
    angle: 0,
    id: new Date().valueOf(),
    left,
    top,
    openingType,
    single: openingsDefault[openingType].single,
    opensInside: openingsDefault[openingType].opensInside,
    opensToRight: openingsDefault[openingType].opensToRight,
    frameType: openingsDefault[openingType].frameType,
    inputAngle: 0,
    distanceFromFloor: openingsDefault[openingType].distanceFromFloor,
    buttonsVertical: false,
    showLeftInput: false,
    showRightInput: false,
    openingHeight: openingsDefault[openingType].openingHeight,
    distanceToLeft: 0,
    distanceToRight: 0,
    attachedToWall: undefined,
  });

  openingsRef.value.push(openingObj);

  canvasRef.value.add(openingObj);
  canvasRef.value.renderAll();
  // openingObj.bringToFront();
  canvasRef.value.setActiveObject(openingObj);
}

function handleMove(options, isOpening = true) {
  //при перетаскивании заменяем топ и лефт на те, которе лежат на нашем отрезке
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, innerPolygon, activeObject, wallsRef } =
    storeToRefs(canvasDataStore);
  const polygon = polygonRef.value;
  //проверяем, не находимся ли мы близко к какой-нибудь стенке
  const stickTo = stickUnstick(options.e, polygon.points);
  const objectID = options.transform.target.id;

  const object = findObject("id", objectID);

  if (stickTo.index !== undefined) {
    //если нашли к чему прилипнуть
    const projection = getMouseProjectionOnWall(
      stickTo.index,
      options.e,
      isOpening
    );
    const matrix = polygon.calcTransformMatrix();
    const newPoint = fabric.util.transformPoint(projection, matrix);
    const wallCoords = getWallCoords(stickTo.index, innerPolygon.value.points);
    let distance = {};
    try {
      distance = calcDistanceToWallEnds(
        projection,
        wallCoords,
        object.widthClean / 2
      );
    } catch (error) {
      console.log(error);
      distance = { distanceToLeft: 0, distanceToRight: 0 };
    }

    const wall = wallsRef.value[stickTo.index];
    object.set({
      left: newPoint.x - polygon.pathOffset.x * initScale,
      top: newPoint.y - polygon.pathOffset.y * initScale,
      distanceToLeft: distance.distanceToLeft,
      distanceToRight: distance.distanceToRight,
      angle: stickTo.slope,
      attachedToWall: stickTo.index,
      showRightInput: distance.distanceToRight > 0,
      showLeftInput: distance.distanceToLeft > 0,
      inputAngle: wall.slopeMod,
      buttonsVertica: wall.buttonsVertical,
    });
  } else {
    object.set({
      distanceToLeft: 0,
      distanceToRight: 0,
      showRightInput: false,
      showLeftInput: false,
      attachedToWall: undefined,
      inputAngle: 0,
    });
  }

  activeObject.value = object;

  if (object.distanceToLeft > 0 || object.distanceToRight > 0) {
    drawObjectMeasures(object);
  } else {
    removeMeasures();
  }
  if (!isOpening) {
    updateIconPos(object);
  }
}

function stickUnstick(mouseEvent, points) {
  //check distance to all walls. stick to nearest if it's less than X mm away
  const res = points.reduce((acc, thisPoint, index) => {
    const wallSegment = getWallFlattenLine(index, points, true),
      { x, y } = transformPointerToPolygonCoords(mouseEvent),
      pointer = point(x, y),
      distance = pointer.distanceTo(wallSegment)[0];
    if (
      distance <= stickAllowance &&
      ((acc.distance && acc.distance > distance) || !acc.distance)
    ) {
      acc.distance = distance;
      acc.index = index;
      acc.slope = fabric.util.radiansToDegrees(wallSegment.slope);
    }
    return acc;
  }, {});
  return res;
}

function handleMoveWithWall(wallIndexes) {
  const canvasDataStore = useCanvasDataStore(),
    { polygonRef, innerPolygon, wallsRef } = storeToRefs(canvasDataStore);

  wallIndexes.forEach((wallIndex) => {
    const objArr = getObjectsOnWall(wallIndex);
    objArr.forEach((obj) => {
      //project current left and top onto moving wall, reassing
      const polygon = obj.openingType ? polygonRef.value : innerPolygon.value;
      const AB = getWallFlattenLine(obj.attachedToWall, polygon.points);
      //делаем обратное преобразование координат середины окна, чтобы спроецировать на стену.
      //середина окна перемещается в систему координат, соответсвующую точкам полигона
      const matrix = polygon.calcTransformMatrix();
      const mInverse = fabric.util.invertTransform(matrix);
      const invertedPoint = fabric.util.transformPoint(
        {
          x: obj.left + polygon.pathOffset.x * initScale,
          y: obj.top + polygon.pathOffset.y * initScale,
        },
        mInverse
      );
      const pointC = point(invertedPoint.x, invertedPoint.y);

      const projection = pointC.projectionOn(AB);
      //потом снова трансформируем
      const newPoint = fabric.util.transformPoint(projection, matrix);
      const newLeft = newPoint.x - polygon.pathOffset.x * initScale;
      const newTop = newPoint.y - polygon.pathOffset.y * initScale;
      const wallCoords = getWallCoords(wallIndex, innerPolygon.value.points);
      const wall = wallsRef.value[wallIndex];
      let distance = {};
      try {
        // если слипся внутренний полигон, временно пусть будет расстояние до краёв 0
        distance = calcDistanceToWallEnds(
          projection,
          wallCoords,
          obj.widthClean / 2
        );
      } catch (error) {
        console.log(error);
        distance = { distanceToLeft: 0, distanceToRight: 0 };
      }

      obj.set({
        left: newLeft,
        top: newTop,
        angle: fabric.util.radiansToDegrees(AB.slope),
        distanceToLeft: distance.distanceToLeft,
        distanceToRight: distance.distanceToRight,
        inputAngle: wall.slopeMod,
      });
      if (obj.communicationType) {
        updateIconPos(obj);
      }
    });
  });
}
function getObjectsOnWall(wallIndex) {
  return filterAllObjects("attachedToWall", wallIndex, true);
}

function calcDistanceToWallEnds(centerPoint, wall, halfWidth) {
  const AB = line(point(wall.x1, wall.y1), point(wall.x2, wall.y2));

  const pointC = point(centerPoint.x, centerPoint.y);
  const projection = pointC.projectionOn(AB);
  const distanceToLeft =
    calcDistance(projection.x, projection.y, wall.x1, wall.y1) - halfWidth;
  const distanceToRight =
    calcDistance(projection.x, projection.y, wall.x2, wall.y2) - halfWidth;

  return { distanceToLeft, distanceToRight };
}

function bringAllObjectstoFront() {
  const canvasDataStore = useCanvasDataStore();
  const { openingsRef, communicationsRef } = storeToRefs(canvasDataStore);
  const allObj = [...openingsRef.value, ...communicationsRef.value];
  allObj.forEach((object) => {
    object.bringToFront();
    if (object.icon) object.icon.bringToFront();
  });
}

function findObject(property, value) {
  const canvasDataStore = useCanvasDataStore();
  const { openingsRef, communicationsRef } = storeToRefs(canvasDataStore);

  const objects = [...openingsRef.value, ...communicationsRef.value];
  return objects.find((obj) => {
    return obj[property] == value;
  });
}

function updateStick() {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, communicationsRef, openingsRef } =
    storeToRefs(canvasDataStore);
  const polygon = polygonRef.value;
  const matrix = polygon.calcTransformMatrix();
  const objects = filterAllObjects("attachedToWall", undefined, false);

  objects.forEach((obj) => {
    //get coords in polygon system (0,0 это центр полигона)
    let centerPoint = {
      x: obj.left + polygon.pathOffset.x * initScale,
      y: obj.top + polygon.pathOffset.y * initScale,
    };
    //получаем точку в системе координат, соответсвующую точкам полигона
    centerPoint = fabric.util.transformPoint(
      centerPoint,
      fabric.util.invertTransform(matrix)
    );

    const res = polygon.points.reduce((acc, thisPoint, index) => {
      const wallSegment = getWallFlattenLine(index, polygon.points, true);
      const flCenterPoint = point(centerPoint.x, centerPoint.y);
      const distance = flCenterPoint.distanceTo(wallSegment)[0];

      if (
        distance <= stickAllowance &&
        ((acc.distance && acc.distance > distance) ||
          acc.distance === undefined)
      ) {
        acc.distance = distance;
        acc.index = index;
      }
      return acc;
    }, {});

    obj.set({ attachedToWall: res.index });
  });
}

function makeOpening(options) {
  const canvasDataStore = useCanvasDataStore();
  const { stage } = storeToRefs(canvasDataStore);
  const wt = canvasDataStore.wallThickness;
  const {
    widthClean,
    openingHeight,
    angle,
    id,
    left,
    top,
    openingType,
    single,
    opensInside,
    opensToRight,
    frameType,
    inputAngle,
    distanceFromFloor,
    buttonsVertical,
    showLeftInput,
    showRightInput,
    distanceToLeft,
    distanceToRight,
    attachedToWall,
  } = options;
  const isArch = openingType === "arch";
  const isWindow = openingType === "window";
  let objArr;

  //поскольку окна и двери/арки строятся по-разному, будем их создавать отдельно
  const params = {
    single,
    widthClean,
    opensToRight,
    wt,
    fill: openingsDefault[openingType].color,
  };
  if (isWindow) {
    params.frameType = frameType;
    objArr = makeWindow(params);
  } else {
    //делаем дверь или арку
    params.isArch = isArch;
    params.opensToRight = opensToRight;
    objArr = makeDoor(params);
  }
  const canChange = stage.value == 1;
  const openingGroup = new fabric.Group(objArr, {
    originX: "center",
    originY: "center",
    flipY: opensInside,
    flipX: opensInside,
    scaleX: initScale,
    scaleY: initScale,
    hasBorders: false,
    hasControls: false,
    openingType,
    angle,
    id,
    widthClean,
    openingHeight,
    left,
    top,
    perPixelTargetFind: true,
    attachedToWall,
    distanceFromFloor,
    frameType,
    inputAngle,
    buttonsVertical,
    showLeftInput,
    showRightInput,
    opensInside,
    opensToRight,
    single,
    distanceToLeft,
    distanceToRight,
    evented: canChange,
    lockMovementX: !canChange,
    lockMovementY: !canChange,
    selectable: canChange,
  });

  //при переходе фокуса с объекта чистим хранилище активного объекта, чтобы скрывались параметры
  initObjectEvents(openingGroup, true);

  return openingGroup;
}
function makeDoor({ isArch, wt, single, widthClean, opensToRight, fill }) {
  const doorjamW = 50;
  const innerW = widthClean - doorjamW * 2;
  const r = (single ? innerW : innerW / 2) + 1;
  const needsLeft = !isArch && (!single || !opensToRight);
  const needsRight = !isArch && (!single || opensToRight);

  //даже если дверь отдностворчатая, добавляем обе окружности, это нужно для корректной центровки
  const rightCircle = new fabric.Circle({
    left: 0,
    top: 0,
    radius: r,
    startAngle: 270,
    endAngle: 0,
    strokeWidth: 2,
    fill: "transparent",
    selectable: false,
    evented: false,
    stroke: needsRight ? "black" : "transparent",
  });
  const leftCircle = new fabric.Circle({
    top: 0,
    left: innerW,
    radius: r,
    startAngle: 180,
    endAngle: 270,
    fill: "transparent",
    selectable: false,
    evented: false,
    stroke: needsLeft ? "black" : "transparent",
    strokeWidth: 2,
  });
  const doorframe = new fabric.Rect({
    left: r - doorjamW,
    top: r - wt / 2,
    width: widthClean,
    height: wt,
    fill,
    stroke: "black",
    strokeWidth: 5,
  });

  const lineLengthLong = wt / 2 + r;
  const lineLengthShort = wt;
  const lineTopLow = r - wt / 2;
  const rightLine = new fabric.Line(
    [0, 0, 0, needsRight ? lineLengthLong : lineLengthShort],
    {
      left: r,
      top: needsRight ? 0 : lineTopLow,
      stroke: "black",
      strokeWidth: 5,
      selectable: false,
      evented: false,
    }
  );
  const leftLine = new fabric.Line(
    [0, 0, 0, needsLeft ? lineLengthLong : lineLengthShort],
    {
      left: single ? r * 2 : r * 3,
      top: needsLeft ? 0 : lineTopLow,
      stroke: "black",
      strokeWidth: 5,
      selectable: false,
      evented: false,
    }
  );
  return [leftCircle, rightCircle, doorframe, leftLine, rightLine];
}
function makeWindow({ frameType, single, widthClean, opensToRight, wt, fill }) {
  //правая створка нужна не глухим окнам,
  //+тем, которые открываются в обе стороны или направо,
  //+одностворчатым, которые открываются направо
  const needsRight =
    frameType !== 0 &&
    (frameType == 1 || frameType === 3 || (single && opensToRight === true));
  const needsLeft =
    frameType !== 0 &&
    (frameType == 1 || frameType === 2 || (single && opensToRight === false));
  const r = single ? widthClean : widthClean / 2;

  //всегда добавляем две окружности, это нужно для корректной центровки
  const rightCircle = new fabric.Circle({
    left: 0,
    top: 0,
    radius: r,
    startAngle: 270,
    endAngle: 0,
    strokeWidth: 2,
    fill: "transparent",
    selectable: false,
    evented: false,
    stroke: needsRight ? "black" : "transparent",

    strokeDashArray: [50, 50],
  });
  const rightLine = new fabric.Line([0, 0, 0, r], {
    left: r,
    top: 0,
    stroke: needsRight ? "black" : "transparent",

    strokeWidth: 3,
    selectable: false,
    evented: false,
    strokeDashArray: [50, 50],
  });
  const leftCircle = new fabric.Circle({
    left: single ? r : widthClean,
    top: 0,
    radius: r,
    startAngle: 180,
    endAngle: 270,
    fill: "transparent",
    selectable: false,
    evented: false,
    stroke: needsLeft ? "black" : "transparent",

    strokeWidth: 2,
    strokeDashArray: [50, 50],
  });
  const leftLine = new fabric.Line([0, 0, 0, r], {
    left: single ? r * 2 : r * 3,
    top: 0,

    stroke: needsLeft ? "black" : "transparent",
    strokeWidth: 3,
    selectable: false,
    evented: false,
    strokeDashArray: [50, 50],
  });
  const rect = new fabric.Rect({
    left: r,
    top: r - wt / 2,
    width: widthClean,
    height: wt,
    fill,
    stroke: "black",
    strokeWidth: 3,
  });
  const glass = new fabric.Rect({
    left: r,
    top: r - wt / 8,
    width: widthClean,
    height: wt / 4,
    fill: "transparent",
    stroke: "black",
    strokeWidth: 2,
  });

  return [rightCircle, leftCircle, rect, glass, rightLine, leftLine];
}

function addCommunications(type) {
  const { canvasRef } = storeToRefs(useCanvasDataStore());

  const canvas = canvasRef.value;
  //при создании бросаем рандомно куда-то ближе к центру
  const min = 0.3 * canvas.width;
  const max = 0.6 * canvas.width;
  const left = Math.floor(Math.random() * (max - min + 1)) + min;
  const top = Math.floor(Math.random() * (max - min + 1)) + min;
  makeComMarker({
    communicationType: type,
    distanceToLeft: 0,
    top,
    left,
    attachedToWall: undefined,
    id: new Date().valueOf(),
    distanceFromFloor: 0,
  });
}

function makeComMarker({
  communicationType,
  distanceToLeft,
  top,
  left,
  attachedToWall,
  id,
  distanceFromFloor,
}) {
  const { canvasRef, communicationsRef, stage, activeObject, wallsRef } =
    storeToRefs(useCanvasDataStore());
  const defaultValues = communicationsDefault[communicationType];
  const circle = new fabric.Circle({
    fill: defaultValues.color,
    radius: 20,
    originX: "center",
    originY: "center",
    top: 10,
    left: 10,
  });
  const triangle = new fabric.Triangle({
    fill: defaultValues.color,
    top: 25,
    left: 2,
    width: 15,
    height: 15,
    flipY: true,
  });
  //второй невидимый треугольник для симметричности группы, чтобы потом в центр прилепить иконку
  const triangle2 = new fabric.Triangle({
    fill: "transparent", //defaultV.color, //
    top: -20,
    left: 2,
    width: 15,
    height: 15,
  });
  let distanceToRight = 0;
  let angle = 0;
  let inputAngle = 0;
  if (attachedToWall !== undefined) {
    const wall = wallsRef.value[attachedToWall];
    distanceToRight = wall.length - distanceToLeft;
    angle = wall.slope;
    inputAngle = wall.slopeMod;
  }
  const canChange = stage.value === 2;
  const shadow = new fabric.Shadow({
    color: "rgba(0,0,0,0.4)",
    blur: 5,
    affectStroke: true,
  });

  const group = new fabric.Group([circle, triangle, triangle2], {
    originX: "center",
    originY: "top",
    hasBorders: false,
    hasControls: false,
    communicationType,
    distanceToLeft: distanceToLeft,
    distanceToRight,
    distanceFromFloor,
    attachedToWall: attachedToWall,
    left,
    top,
    id,
    widthClean: 0,
    flipY: true,
    evented: canChange,
    lockMovementX: !canChange,
    lockMovementY: !canChange,
    angle,
    inputAngle,
    hoverCursor: canChange ? "move" : "default",
    shadow,
  });
  communicationsRef.value.push(group);
  let icon;

  //добавляем иконку
  fabric.loadSVGFromURL(defaultValues.icon, function (objects, options) {
    icon = fabric.util.groupSVGElements(objects, options);
    const groupCenterPoint = group.getPointByOrigin("center", "center");
    icon.set({
      top: groupCenterPoint.y,
      left: groupCenterPoint.x,
      originX: "center",
      originY: "center",
      stroke: defaultValues.stroke,
      fill: defaultValues.stroke,
      hasBorders: false,
      selectable: false,
      evented: false,
    });
    group.set("icon", icon);
    canvasRef.value.add(group, icon).renderAll();
    if (canChange) canvasRef.value.setActiveObject(group);
    // activeObject.value = group;
  });
  initObjectEvents(group, false);
}

function initObjectEvents(obj, isOpening) {
  const canvasDataStore = useCanvasDataStore();
  const { activeObject, canvasRef, wallsRef } = storeToRefs(canvasDataStore);
  obj.on("moving", function (opt) {
    handleMove(opt, isOpening);
  });

  // при выборе объекта нарисуем ему размерные линии, покажем параметры
  obj.on("selected", function (opt) {
    activeObject.value = opt.target;
    canvasRef.value.calcViewportBoundaries();
    wallsRef.value.forEach((wall) => {
      if (wall.selected) {
        wall.set({ stroke: wallColor, selected: false });
        removeCursor();
      }
    });

    drawObjectMeasures(opt.target);
    positionAllInputs();
  });

  obj.on("deselected", (opt) => {
    activeObject.value = {};
  });

  //на hover будем немножко убирать opacity
  obj.on("mouseover", function (opt) {
    const opening = opt.target;
    if (!opening) return;
    opening.set({ opacity: 0.85 });
    canvasRef.value.renderAll();
  });
  obj.on("mouseout", function (opt) {
    const opening = opt.target;
    if (!opening) return;
    opening.set({ opacity: 1 });
    canvasRef.value.renderAll();
  });
}

//при движении маркера положение иконки приходится обновлять вручную, тк она не в группе
function updateIconPos(marker) {
  const icon = marker.icon;
  const groupCenterPoint = marker.getPointByOrigin("center", "center");
  //просто ставим центр иконки в центр маркера
  icon.set({
    top: groupCenterPoint.y,
    left: groupCenterPoint.x,
  });
  // icon.setCoords();
}

//фильтрует по какому-то параметру все объекты скопом
function filterAllObjects(property, value, isEqual) {
  const { communicationsRef, openingsRef } = storeToRefs(useCanvasDataStore());
  return [...communicationsRef.value, ...openingsRef.value].filter((obj) => {
    return isEqual ? obj[property] === value : obj[property] !== value;
  });
}

export {
  addOpening,
  handleMoveWithWall,
  bringAllObjectstoFront,
  findObject,
  updateStick,
  makeOpening,
  addCommunications,
  updateIconPos,
  makeComMarker,
  openingsDefault,
  communicationsDefault,
};
