/* eslint-disable no-unused-vars */
import { fabric } from "fabric";
import { storeToRefs } from "pinia";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { checkOrthogonal, removeCursor } from "@/script/wallsUtils";
import { line, point, segment } from "@flatten-js/core";
import {
  getNextIndexInLoop,
  getWallFlattenLine,
  pointAtLength,
} from "./generalUtils";
import { initScale, getAbsoluteCoords, wallColor } from "@/script/canvasUtils";

const serifLength = 40;

//функция рисует ортогональные размерные линии
function drawOrthMeasureLines() {
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef, polygonRef, wallsRef } = storeToRefs(canvasDataStore);
  const poly = polygonRef.value;
  const aCoords = poly.aCoords;
  const polyMatrix = polygonRef.value.calcTransformMatrix();
  const invertMatrix = fabric.util.invertTransform(polyMatrix);
  removeMeasures();
  wallsRef.value.forEach((wall) => {
    wall.set({ stroke: wallColor, selected: false });
    removeCursor();
  });
  //получаем координаты углов описывающего прямоугольника в системе координат полигона
  //берем aCoords, которые в системе координат канваса, и делаем  трансформацию обратной матрицей полигона
  //получаем систему координат, где 0,0 соответствует 0,0 изначальных точек
  const invTL = fabric.util.transformPoint(aCoords.tl, invertMatrix);
  const invTR = fabric.util.transformPoint(aCoords.tr, invertMatrix);
  const invBL = fabric.util.transformPoint(aCoords.bl, invertMatrix);
  //затем, прибавляем полигоний оффсет, и получаем координаты описывающего прямоугольника в системе координат
  //соответствующую полигоньим точкам.
  const transformedTL = {
    x: invTL.x + poly.pathOffset.x,
    y: invTL.y + poly.pathOffset.y,
  };
  const transformedTR = {
    x: invTR.x + poly.pathOffset.x,
    y: invTR.y + poly.pathOffset.y,
  };
  const transformedBL = {
    x: invBL.x + poly.pathOffset.x,
    y: invBL.y + poly.pathOffset.y,
  };

  //разобъем создание линий на направления - Верх, Низ, Лево, Право
  //у каждого своя ось, от которой надо отталкиваться либо в минусовую либо в плюсовую сторону
  const directionParams = [
    {
      direction: "left",
      initialCoord: transformedTL.x,
      offsetSign: -1,
      isX: true,
    },
    {
      direction: "right",
      initialCoord: transformedTR.x,
      offsetSign: 1,
      isX: true,
    },
    {
      direction: "top",
      initialCoord: transformedTL.y,
      offsetSign: -1,
      isX: false,
    },
    {
      direction: "bottom",
      initialCoord: transformedBL.y,
      offsetSign: 1,
      isX: false,
    },
  ];
  removeMeasures();
  let allMeasures = [];

  //берем ортогональные стены и проставляем им направление, где они должны отображаться
  let wallsCoords = categorizeOrthoWalls();

  if (wallsCoords.length == 0) {
    return;
  }

  directionParams.forEach((param) => {
    const { direction, isX, initialCoord, offsetSign } = param;

    //берем ортогональные стены по текущему направлению
    let linesArr = wallsCoords
      .filter((wall) => wall.direction == direction)
      .map((item) => item.line);

    linesArr = sortAndGroup(linesArr, isX ? "x" : "y");
    const offset = {
      x: polygonRef.value.pathOffset.x * initScale,
      y: polygonRef.value.pathOffset.y * initScale,
    };
    //получаем массив групп линий по конкретной стороне
    const measureGroup = linesArr.reduce((acc, walls, index) => {
      //wall это теперь массив
      walls.forEach((wall) => {
        acc.push(
          makeMeasureLineGroup(
            wall,
            index,
            isX,
            initialCoord,
            offsetSign,
            polyMatrix,
            offset
          )
        );
      });
      return acc;
    }, []);
    allMeasures = [...allMeasures, ...measureGroup];
  });
  if (allMeasures.length > 0) {
    //собственно рисуем все линии которые получились на холсте
    allMeasures.forEach((measure) => {
      canvasRef.value.add(measure);
      measure.setCoords();
      measure.bringToFront();
      wallsRef.value[measure.index[0]].set({
        hasMeasureLine: true,
        innerMeasure: false,
        showInput: true,
      });
    });
  }
}

function removeMeasures() {
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef, wallsRef } = storeToRefs(canvasDataStore);
  const oldMeasureLines = getAllMeasureLines();
  oldMeasureLines.forEach((linegroup) => {
    canvasRef.value.remove(linegroup);
    if (linegroup.index[0] !== undefined) {
      wallsRef.value[linegroup.index[0]].set({
        hasMeasureLine: false,
        innerMeasure: false,
        showInput: false,
      });
    }
  });
}

function getAllMeasureLines() {
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef } = storeToRefs(canvasDataStore);
  return canvasRef.value.getObjects("group").filter((obj) => {
    return obj.groupType && obj.groupType.includes("measureLine");
  });
}

//назначает ортогональным линиям направление, где они должны отображаться.
function categorizeOrthoWalls() {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, innerPolygon } = storeToRefs(canvasDataStore);
  const innerPoints = innerPolygon.value.points;
  const polygon = polygonRef.value;
  const aCoords = polygon.aCoords;

  const matrix = polygon.calcTransformMatrix();
  const invertM = fabric.util.invertTransform(matrix);
  //получаем точку в системе координат, соответствующей точкам полигона
  const newPoint = fabric.util.transformPoint(aCoords.tl, invertM);

  const controlPoint = point(
    newPoint.x + polygon.pathOffset.x,
    newPoint.y + polygon.pathOffset.y
  );

  return innerPoints.reduce((acc, thisPoint, index) => {
    const lineOrthagonality = checkOrthogonal(index);

    const nextIndex = getNextIndexInLoop(index, innerPoints.length);
    let nextPoint = innerPoints[nextIndex];
    const pointA = point(thisPoint.x, thisPoint.y);
    let pointB = point(nextPoint.x, nextPoint.y);
    const ortLine = line(pointA, pointB);
    const thisLineParams = {
      x2: nextPoint.x,
      y2: nextPoint.y,
      x1: thisPoint.x,
      y1: thisPoint.y,
      index: [index],
    };

    //берем вертикальные линии
    if (lineOrthagonality.isVertical) {
      thisLineParams.isHorizontal = false;
      //проверяем лежит линия слева или справа от top left
      if (controlPoint.leftTo(ortLine)) {
        //дополнительно проверяем, нет ли уже точно такой же линии с другой стороны.
        const found = findSimilarMeasure(acc, thisLineParams, "y");

        if (!found) {
          acc.push({ line: thisLineParams, direction: "right" });
        }
      } else {
        const found = findSimilarMeasure(acc, thisLineParams, "y");

        if (!found) {
          acc.push({ line: thisLineParams, direction: "left" });
        }
      }
    } else if (lineOrthagonality.isHorizontal) {
      thisLineParams.isHorizontal = true;
      //аналогично горизонтальные
      if (controlPoint.leftTo(ortLine)) {
        const found = findSimilarMeasure(acc, thisLineParams, "x");

        if (!found) {
          acc.push({ line: thisLineParams, direction: "bottom" });
        }
      } else {
        const found = findSimilarMeasure(acc, thisLineParams, "x");

        if (!found) {
          acc.push({ line: thisLineParams, direction: "top" });
        }
      }
    }
    return acc;
  }, []);
}

//Проверяет, нет ли уже такой линии в массиве.
//Например у прямоугольника нет смысла отрисовывать размерную линию и для левой и для правой стороны,
//нужны только верхняя и правая. Это мы и делаем
function findSimilarMeasure(acc, lineB, axis) {
  const found = acc.find((lineA) => {
    return (
      //округляем, тк иногда сравнивает х=4149.9999 и х=4150. откуда вообще взялся 4149.9999 не понятно.
      (Math.round(lineA.line[axis + 1]) == Math.round(lineB[axis + 1]) &&
        Math.round(lineA.line[axis + 2]) == Math.round(lineB[axis + 2])) ||
      (Math.round(lineA.line[axis + 1]) == Math.round(lineB[axis + 2]) &&
        Math.round(lineA.line[axis + 2]) == Math.round(lineB[axis + 1]))
    );
  });
  if (found) {
    found.line.index = [...found.line.index, ...lineB.index];
  }
  return found !== undefined;
}

//сортируем по длине и группируем вместе те линии, которые не пересекаются
function sortAndGroup(wallsArr, axis) {
  const { getWallLength } = useCanvasDataStore();
  const sorted = wallsArr.sort((a, b) => {
    const lengthA = getWallLength(a.index[0]);
    const lengthB = getWallLength(b.index[0]);

    return lengthA - lengthB;
  });
  let grouped = [];

  sorted.forEach((thisWall) => {
    if (grouped.length == 0) {
      grouped.push([thisWall]);
      return;
    }
    const thisWallSeg = segment(
      thisWall.x1,
      thisWall.y1,
      thisWall.x2,
      thisWall.y2
    );
    let groupedThisWall = false;
    grouped.forEach((group) => {
      //обойти все группы, найти, нельзя ли куда-нибудь вставить эту размерную линию
      let foundInersectionInGroup = false;
      group.forEach((existingWall) => {
        //проверяем, не пересекаются ли размерные линии
        const existingWallSeg = segment(
          existingWall.x1,
          existingWall.y1,
          existingWall.x2,
          existingWall.y2
        );
        if (checkIntersection(thisWallSeg, existingWallSeg)) {
          foundInersectionInGroup = true;
          return;
        }
      });
      if (!foundInersectionInGroup) {
        //если обошли все размерные линии в группе и не нашли пересечейний - добавляем
        group.push(thisWall);
        groupedThisWall = true;

        return;
      }
    });
    if (!groupedThisWall) {
      //если не нашли куда добавить, делаем новую группу

      grouped.push([thisWall]);
    }
  });

  return grouped;
}

//проверяем пересечение, проецируем одно на другое.
//Если пересекаются больше чем в одной точке - говорим что есть пересечение
function checkIntersection(segmentA, segmentB) {
  const lineA = line(segmentA.start, segmentA.end);
  //project one segment onto another then check for intersection
  const BStartProj = segmentB.start.projectionOn(lineA);
  const BEndProj = segmentB.end.projectionOn(lineA);
  const projectedBSegment = segment(BStartProj, BEndProj);
  const res = segmentA.intersect(projectedBSegment).length > 1;

  return res;
}

//Считаем координаты для размерной линии - саму линию и зарубки на концах.
//Создаем фабриковые линии, собираем в группу
function makeMeasureLineGroup(
  wall,
  layer,
  isX,
  initialCoord,
  offsetSign,
  matrix,
  polygonOffset
) {
  //берем исходные координаты отрезка, делаем отступ от края bounding rectangle, кратный слою
  const index = wall.index;
  const offsetStep = 25;

  const offset = offsetSign * (layer + 1) * offsetStep;
  const newAxisValue = initialCoord + offset;

  // const length = getWallLineByIndex(index).length;
  let measureLineCoords, startMark, endMark;
  if (isX) {
    //coords in order x1,y1,x2,y2
    measureLineCoords = [newAxisValue, wall.y1, newAxisValue, wall.y2];
    startMark = [
      newAxisValue - serifLength,
      wall.y1,
      newAxisValue + serifLength,
      wall.y1,
    ];
    endMark = [
      newAxisValue - serifLength,
      wall.y2,
      newAxisValue + serifLength,
      wall.y2,
    ];
  } else {
    measureLineCoords = [wall.x1, newAxisValue, wall.x2, newAxisValue];
    startMark = [
      wall.x1,
      newAxisValue - serifLength,
      wall.x1,
      newAxisValue + serifLength,
    ];
    endMark = [
      wall.x2,
      newAxisValue - serifLength,
      wall.x2,
      newAxisValue + serifLength,
    ];
  }

  //создаем линии
  const measureLinePieces = makeLine([measureLineCoords, startMark, endMark]);

  //найти середину линии, трансформировать эту точку матрицей полигона и поставить как top & left
  const midpoint = segment(
    measureLineCoords[0],
    measureLineCoords[1],
    measureLineCoords[2],
    measureLineCoords[3]
  ).middle();
  const transformedPoint = fabric.util.transformPoint(
    { x: midpoint.x, y: midpoint.y },
    matrix
  );

  //собираем в группу, чтобы не расползались
  const measureLineGroup = new fabric.Group(measureLinePieces, {
    selectable: false,
    scaleX: initScale,
    scaleY: initScale,
    groupType: "measureLine",
    index: index,
    originX: "center",
    originY: "center",
    hoverCursor: "default",
    hasBorders: false,
    evented: false,
    top: transformedPoint.y - polygonOffset.y + (isX ? 0 : offset),
    left: transformedPoint.x - polygonOffset.x + (isX ? offset : 0),
  });

  return measureLineGroup;
}

//собственно создание фабриковой линии из координат
function makeLine(coordsArr) {
  return coordsArr.map((coords) => {
    return new fabric.Line(coords, {
      fill: "transparent",
      stroke: "black",
      strokeWidth: 10,
      selectable: false,
      evented: false,
      hasBorders: false,
      hoverCursor: "default",
      originX: "center",
      originY: "center",
    });
  });
}

function positionAllInputs() {
  const measureLines = getAllMeasureLines();
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef } = storeToRefs(canvasDataStore);
  canvasRef.value.calcViewportBoundaries();
  measureLines.forEach((line) => {
    try {
      positionInput(line);
    } catch (e) {
      console.log("failed to position inputs", e);
    }
  });
}

//measureLine - фабриковая группа - размерная линия
function positionInput(measureLine) {
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef, wallsRef, activeObject } = storeToRefs(canvasDataStore);
  //const canvas = canvasRef.value;

  let collides;
  let inputGroup;

  //для внутренних и внешних размерных линий
  if (measureLine.isRight === undefined) {
    //помним, что у размерной линии индексов может быть несколько, берем первый
    const correspondingWall = wallsRef.value[measureLine.index[0]];
    inputGroup = document.getElementById(correspondingWall.inputId);

    const absCoords = getAbsoluteCoords(measureLine);

    inputGroup.style.left = absCoords.x - inputGroup.offsetWidth / 2 + "px";
    inputGroup.style.top = absCoords.y - inputGroup.offsetHeight / 2 + "px";
    //nextTick(() => (
    collides = calcCollision(inputGroup);
    correspondingWall.showInput = !collides;
    //));
  } else {
    //для размерных линий проемов
    inputGroup = document.getElementById(measureLine.inputId);
    const absCoords = getAbsoluteCoords(measureLine, true);
    inputGroup.style.left = absCoords.x - inputGroup.offsetWidth / 2 + "px";
    inputGroup.style.top = absCoords.y - inputGroup.offsetHeight / 2 + "px";

    const property = `show${measureLine.isRight ? "Right" : "Left"}Input`;
    collides = calcCollision(inputGroup);
    activeObject.value.set({
      [property]: !collides,
    });
  }

  if (collides) {
    inputGroup.style.left = "-100px";
    inputGroup.style.top = "-100px";
  }
}

//Рассчитываем, не выползает ли инпут за границы канваса.
function calcCollision(inputGroup) {
  const inputGroupBR = inputGroup.getBoundingClientRect();
  const canvasBCR = document.getElementById("canvas").getBoundingClientRect();
  const res =
    canvasBCR.x <= inputGroupBR.x &&
    canvasBCR.y <= inputGroupBR.y &&
    canvasBCR.bottom >= inputGroupBR.bottom &&
    canvasBCR.right >= inputGroupBR.right;
  return !res;
}

function getMeasureLine(index) {
  const allMeasures = getAllMeasureLines();
  return allMeasures.find((measure) => {
    return measure.index.includes(index);
  });
}

function drawInnerMeasure(wall) {
  //берем стену, берем соответствующую грань внутреннего полигона,
  //по нормали строим из обоих концов перпендикуляры, соединяем их середины
  //позиционируем саму линию
  //ставим признаки наличия линии и видимостии инпута, позиционируем инпут
  // removeMeasures();
  const canvasDataStore = useCanvasDataStore();
  const { innerPolygon, canvasRef, wallsRef } = storeToRefs(canvasDataStore);
  const innerPoly = innerPolygon.value;
  const innerPoints = innerPoly.points;
  const index = wall.wallIndex;
  wallsRef.value[index].set({
    hasMeasureLine: true,
    showInput: true,
    innerMeasure: true,
  });
  const innerSerifLegth = serifLength * 4;

  const wallLine = getWallFlattenLine(index, innerPoints);

  const wallSegment = getWallFlattenLine(index, innerPoints, true);
  if (wallSegment.ps.equalTo(wallSegment.pe)) {
    return;
  }
  const measureCoords = calcMeasureCoordinates(
    wallLine,
    wallSegment,
    innerSerifLegth
  );
  const measureLinePieces = makeLine([
    measureCoords.start,
    measureCoords.main,
    measureCoords.end,
  ]);
  //найти середину линии, трансформировать эту точку матрицей полигона и поставить как top & left
  const midpoint = segment(
    measureCoords.main[0],
    measureCoords.main[1],
    measureCoords.main[2],
    measureCoords.main[3]
  ).middle();
  const transformedPoint = fabric.util.transformPoint(
    {
      x: midpoint.x - innerPoly.pathOffset.x,
      y: midpoint.y - innerPoly.pathOffset.y,
    },
    innerPoly.calcTransformMatrix()
  );

  const measureLineGroup = new fabric.Group(measureLinePieces, {
    scaleX: initScale,
    scaleY: initScale,
    groupType: "measureLine",
    index: [index],
    originX: "center",
    originY: "center",
    hoverCursor: "default",
    top: transformedPoint.y,
    left: transformedPoint.x,
    hasBorders: false,
    selectable: false,
    evented: false,
    //flipY: true,
  });
  canvasRef.value.add(measureLineGroup);
  positionInput(measureLineGroup);
}

function calcMeasureCoordinates(line, segment, serifLength) {
  //началом правой и левой засечки будут концы исходной линии
  //найдем координаты перпендикуляров заданной длины, построенных их концов линии
  //сначала удлиним единичный вектор до нужной длины
  const norm = {
    x: line.norm.x * serifLength,
    y: line.norm.y * serifLength,
  };
  //отложим этот вектор из нужных точек - начала и конца исходного отрезка
  const startMarkCoords = [
    segment.ps.x,
    segment.ps.y,
    segment.ps.x + norm.x,
    segment.ps.y + norm.y,
  ];
  const endMarkCoords = [
    segment.pe.x,
    segment.pe.y,
    segment.pe.x + norm.x,
    segment.pe.y + norm.y,
  ];

  const mainLineCoords = [
    segment.ps.x + norm.x / 2,
    segment.ps.y + norm.y / 2,
    segment.pe.x + norm.x / 2,
    segment.pe.y + norm.y / 2,
  ];

  return { start: startMarkCoords, main: mainLineCoords, end: endMarkCoords };
}

function drawObjectMeasures(
  obj
  //, drawRight = true, drawLeft = true
) {
  removeMeasures();
  const canvasDataStore = useCanvasDataStore();
  if (obj.attachedToWall === undefined) return;
  const { innerPolygon, canvasRef } = storeToRefs(canvasDataStore);
  const innerPoly = innerPolygon.value;
  //найти координаты начала и конца левой и правой линий, нарисовать зарубки, отрисовать линии.
  const innerSerifLegth = serifLength * 4;
  const innerWall = getWallFlattenLine(
    obj.attachedToWall,
    innerPoly.points,
    true
  );

  //левая линия - от левой стены до левого края проема,
  const leftStart = point(innerWall.ps.x, innerWall.ps.y);
  const leftPoint = pointAtLength(
    innerWall.ps.x,
    innerWall.ps.y,
    innerWall.pe.x,
    innerWall.pe.y,
    obj.distanceToLeft
  );
  const leftEnd = point(leftPoint.x, leftPoint.y);
  let leftMeasureLineGroup;
  let rightMeasureLineGroup;
  if (!leftStart.equalTo(leftEnd) && obj.distanceToLeft > 0) {
    const leftLine = line(leftStart, leftEnd);
    const leftSegment = segment(leftStart, leftEnd);
    const leftMeasureCoords = calcMeasureCoordinates(
      leftLine,
      leftSegment,
      innerSerifLegth
    );
    const leftMeasureLinePieces = makeLine([
      leftMeasureCoords.start,
      leftMeasureCoords.main,
      leftMeasureCoords.end,
    ]);
    //найти середину линии, трансформировать эту точку матрицей полигона и поставить как top & left
    const leftMidpoint = segment(
      leftMeasureCoords.main[0],
      leftMeasureCoords.main[1],
      leftMeasureCoords.main[2],
      leftMeasureCoords.main[3]
    ).middle();
    const transformedLeftPoint = fabric.util.transformPoint(
      {
        x: leftMidpoint.x - innerPoly.pathOffset.x,
        y: leftMidpoint.y - innerPoly.pathOffset.y,
      },
      innerPoly.calcTransformMatrix()
    );

    leftMeasureLineGroup = new fabric.Group(leftMeasureLinePieces, {
      selectable: false,
      scaleX: initScale,
      scaleY: initScale,
      groupType: "measureLineLeft",
      inputId: "measureInputGroupLeft",
      index: [],
      isRight: false,
      originX: "center",
      originY: "center",
      hoverCursor: "default",
      hasBorders: false,
      top: transformedLeftPoint.y,
      left: transformedLeftPoint.x,
    });
  }

  //правая линия - от правого края проема до правой стены

  const rightPoint = pointAtLength(
    innerWall.pe.x,
    innerWall.pe.y,
    innerWall.ps.x,
    innerWall.ps.y,
    obj.distanceToRight
  );
  const rightStart = point(rightPoint.x, rightPoint.y);
  const rightEnd = point(innerWall.pe.x, innerWall.pe.y);
  if (!rightStart.equalTo(rightEnd) && obj.distanceToRight > 0) {
    const rightLine = line(rightStart, rightEnd);
    const rightSegment = segment(rightStart, rightEnd);
    const rightMeasureCoords = calcMeasureCoordinates(
      rightLine,
      rightSegment,
      innerSerifLegth
    );
    const rightMeasureLinePieces = makeLine([
      rightMeasureCoords.start,
      rightMeasureCoords.main,
      rightMeasureCoords.end,
    ]);
    //найти середину линии, трансформировать эту точку матрицей полигона и поставить как top & left
    const rightMidpoint = segment(
      rightMeasureCoords.main[0],
      rightMeasureCoords.main[1],
      rightMeasureCoords.main[2],
      rightMeasureCoords.main[3]
    ).middle();
    const transformedRightPoint = fabric.util.transformPoint(
      {
        x: rightMidpoint.x - innerPoly.pathOffset.x,
        y: rightMidpoint.y - innerPoly.pathOffset.y,
      },
      innerPoly.calcTransformMatrix()
    );

    rightMeasureLineGroup = new fabric.Group(rightMeasureLinePieces, {
      selectable: false,
      scaleX: initScale,
      scaleY: initScale,
      groupType: "measureLineRight",
      inputId: "measureInputGroupRight",
      index: [],
      isRight: true,
      originX: "center",
      originY: "center",
      hoverCursor: "default",
      hasBorders: false,
      top: transformedRightPoint.y,
      left: transformedRightPoint.x,
    });
  }
  if (leftMeasureLineGroup) {
    canvasRef.value.add(leftMeasureLineGroup);
  }
  if (rightMeasureLineGroup) {
    canvasRef.value.add(rightMeasureLineGroup);
  }
  if (leftMeasureLineGroup) {
    positionInput(leftMeasureLineGroup);
  }
  if (rightMeasureLineGroup) {
    positionInput(rightMeasureLineGroup);
  }
}

export {
  drawOrthMeasureLines,
  removeMeasures,
  positionAllInputs,
  getMeasureLine,
  positionInput,
  drawInnerMeasure,
  drawObjectMeasures,
};
