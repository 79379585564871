/* eslint-disable no-unused-vars */
import texture from "@/assets/10-sandstone-texture.jpg";
import { fabric } from "fabric";
import {
  polygonPositionHandler,
  actionHandler,
  anchorWrapper,
  simplifyPolygon,
} from "@/script/polygonUtils";
import { getPrevIndexInLoop } from "./generalUtils";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import offsetPolygon from "offset-polygon";
import {
  drawOrthMeasureLines,
  positionAllInputs,
  removeMeasures,
} from "@/script/measureUtils";
import { makeWalls, removeCursor } from "@/script/wallsUtils";
// import { segment, point, box } from "@flatten-js/core";
import cursorTransformPoint from "@/assets/cursorTransformPoint.svg";
import cursorTransformPointPNG from "@/assets/cursorTransformPoint.png";
export const initScale = 0.15;
export const wallColor = "#d6d6d6";
const minZoom = 0.6;
const maxZoom = 3;

//canvasElRef - ссылка на html элемент canvas
//
function initCanvas(canvasElRef) {
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef } = storeToRefs(canvasDataStore);

  canvasRef.value = new fabric.Canvas(canvasElRef, {
    fireRightClick: true,
    stopContextMenu: true,
    controlsAboveOverlay: true,
    preserveObjectStacking: true,
    zoomEnabled: true,
    enablePointerEvents: true,
    selection: false,
    backgroundColor: "white",
  });

  //object - фабриковая группа в которой лежат fabric.line от размерной линии
  //функция нужна для вычисления абсолютных координат середины размерной линии, чтобы туда поставить инпут

  // create a polygon object
  initPolygon();
  initCanvasEvents(canvasRef.value);
}

function getAbsoluteCoords(object) {
  const { canvasRef, polygonRef } = storeToRefs(useCanvasDataStore());
  //матрица трансформации, которая была применена к холсту в целом (pan,zoom, etc)
  const canvas = canvasRef.value;
  const vpt = canvas.viewportTransform;
  // const lineFullyVisible = object.isOnScreen() && !object.isPartiallyOnScreen();
  // const lineInvisible = !object.isOnScreen();
  let newPoint = {
    x: object.left,
    y: object.top,
  };

  // const newTL = canvas.vptCoords.tl;
  // const newTR = canvas.vptCoords.tr;
  // const newBL = canvas.vptCoords.bl;
  // const newBR = canvas.vptCoords.br;
  // const { x1, y1, x2, y2 } = object._objects[0];
  // const lineM = object.calcTransformMatrix();
  // const polyM = polygonRef.value.calcTransformMatrix();
  // const ps = fabric.util.transformPoint(
  //   { x: x1, y: y1 },
  //   polyM
  // );
  // const pe = fabric.util.transformPoint(
  //   { x: x2, y: y2 },
  //   polyM
  // );
  // pe.x -= polygonRef.value.pathOffset.x * initScale;
  // ps.x -= polygonRef.value.pathOffset.x * initScale;
  // pe.y -= polygonRef.value.pathOffset.y * initScale;
  // ps.y -= polygonRef.value.pathOffset.y * initScale;

  // if (object.isPartiallyOnScreen()) {
  //если линия видна целиком - действуем как обычно, а если нет, то
  //"очерчиваем" внутренний периметр холста, отстоящий от границы на пол-ширины инпута.
  //const inputHalfWidth = 10;

  // newTL.x += inputHalfWidth;
  // newTL.y += inputHalfWidth;
  // newBR.x -= inputHalfWidth;
  // newBR.y -= inputHalfWidth;
  // newTR.x -= inputHalfWidth;
  // newTR.y += inputHalfWidth;
  // newBL.x += inputHalfWidth;
  // newBL.y -= inputHalfWidth;
  //проверяем, находится ли середина линии внутри этого очерченного с отступом прямоугольника
  // const midpointInside =
  //   object.left >= newTL.x &&
  //   object.left <= newBR.x &&
  //   object.top >= newTL.y &&
  //   object.top <= newBR.y;
  // if (!midpointInside) {
  //если середина линии выходит за границу,
  //то находим, где размерная линия пересекается с границей
  //одна из этих точек(если их две) станет новым центром инпута
  //     const { tl, tr, bl, br } = object.aCoords;
  //     const mainLine = segment(
  //       point((tl.x + tr.x) / 2, (tl.y + tr.y) / 2),
  //       point((bl.x + br.x) / 2, (bl.y + br.y) / 2)
  //     );
  //     const rect = box(newTL.x, newTL.y, newBR.x, newBR.y);
  //     const intersection = mainLine.intersect(rect);
  //     //пройдемся по точкам пересечения линии и границы, найдем ближайшую к центру
  //     const midpoint = point(object.left, object.top);

  //     const { closestPoint } = intersection.reduce((acc, p) => {
  //       const dist = p.distanceTo(midpoint);
  //       if (acc.distance === undefined || acc.distance > dist) {
  //         acc.distance = dist;
  //         acc.closestPoint = p;
  //       }
  //       return acc;
  //     }, {});
  //     if (closestPoint) {
  //       newPoint = { x: closestPoint.x, y: closestPoint.y };
  //     }
  //   }
  // }

  //применим матрицу холста,
  //и мы попадем в систему координат, где 0,0 это верхний левый угол холста
  newPoint = fabric.util.transformPoint(newPoint, vpt);
  //this._offset - это расстояние от краев окна до холста
  newPoint.x += canvas._offset.left;
  newPoint.y += canvas._offset.top;

  return newPoint;
}

function initPolygon() {
  const canvasDataStore = useCanvasDataStore();
  const {
    canvasRef,
    polygonRef,
    innerPolygon,
    innerPolygon2,
    innerPolygon3,
    wallsRef,
    initPoints,
    stage,
  } = storeToRefs(canvasDataStore);
  const wt = canvasDataStore.wallThickness;
  const points = initPoints.value;

  polygonRef.value = new fabric.Polygon(points, {
    fill: "transparent",
    strokeWidth: wt,
    stroke: wallColor,
    scaleX: initScale,
    scaleY: initScale,
    objectCaching: false,
    transparentCorners: false,
    hasBorders: false,
    cornerStyle: "circle",
    cornerColor: "#10b981",
    controls: generateControls(points),
    hasControls: stage.value == 1,
    strokeUniform: false,
    perPixelTargetFind: true,
    lockMovementX: true,
    lockMovementY: true,
    hoverCursor: "default",
    originX: "center",
    originY: "center",
    selectable: stage.value == 1,
  });
  // Это фоновый рисунок - пол.
  fabric.util.loadImage(texture, function (img) {
    polygonRef.value.set(
      "fill",
      new fabric.Pattern({
        source: img,
        repeat: "repeat",
      })
    );

    canvasRef.value.renderAll();
  });

  polygonRef.value.on("mouseup", (opt) => {
    const evt = opt.e;
    if (evt.button == 0) {
      //clear walls selection
      wallsRef.value.forEach((wall) => {
        if (wall.selected) {
          wall.set({ stroke: wallColor, selected: false });
          removeMeasures();
          removeCursor();
        }
      });
      drawOrthMeasureLines();
      positionAllInputs();
    }
  });
  const innerPoints = offsetPolygon(points, -wt / 2, 0);
  const shadow = new fabric.Shadow({
    color: "black",
    blur: 60,
    affectStroke: true,
  });
  const shadow2 = new fabric.Shadow({
    color: "black",
    blur: 80,
    affectStroke: true,
  });
  const commonProps = {
    fill: "transparent",
    scaleX: initScale,
    scaleY: initScale,
    objectCaching: false,
    hasBorders: false,
    hasControls: false,
    strokeUniform: false,
    lockMovementX: true,
    lockMovementY: true,
    evented: false,
    selectable: false,
    hoverCursor: "default",
    originX: "center",
    originY: "center",
  };
  innerPolygon.value = new fabric.Polygon(innerPoints, {
    ...commonProps,
    stroke: "#404040",
    strokeWidth: 4,
  });
  // два полигона с тенями, для жыру. Разные strokeWidth, чтобы тени не скакали при зуме
  innerPolygon2.value = new fabric.Polygon(innerPoints, {
    ...commonProps,
    stroke: wallColor,
    strokeWidth: 6,
    shadow: shadow2,
  });
  innerPolygon3.value = new fabric.Polygon(innerPoints, {
    ...innerPolygon.value,
    stroke: wallColor,
    strokeWidth: 9,
    shadow,
  });

  canvasRef.value.add(
    polygonRef.value,
    innerPolygon.value,
    innerPolygon2.value,
    innerPolygon3.value
  );
  polygonRef.value.setCoords();

  makeWalls();

  polygonRef.value.sendToBack();
  innerPolygon.value.bringToFront();
  const centerPoint = polygonRef.value.getCenterPoint();
  innerPolygon.value.setPositionByOrigin(centerPoint, "center", "center");
  innerPolygon2.value.setPositionByOrigin(centerPoint, "center", "center");
  innerPolygon3.value.setPositionByOrigin(centerPoint, "center", "center");
  canvasRef.value.setActiveObject(polygonRef.value);

  drawOrthMeasureLines();
}

//points - это точки основного полигона
//на них будут располагаться контролы для трансформации полигона - таскания за углы.
function generateControls(points) {
  //const shadow = new fabric.Shadow({ color: "rgba(0,0,0,0.4)", blur: 5 });
  const pointControls = points.reduce(function (acc, point, index) {
    const newControl = new fabric.Control({
      //функция, которая отвечает за то, ГДЕ находится контрол
      positionHandler: polygonPositionHandler,
      //функция которая отвечает за то, ЧТО делает контрол
      //она обернута в anchorWrapper, который отвечает за то, чтобы полигон не уезжал во время трансформации
      actionHandler: anchorWrapper(index > 0 ? index - 1 : 1, actionHandler),
      //после окончания трансформации убираем лишние точки
      mouseUpHandler: simplifyPolygon,
      actionName: "modifyPolygon",
      pointIndex: index,
      adjacentWallAIndex: index,
      adjacentWallBIndex: getPrevIndexInLoop(index, points.length),
      //cursorStyle: `url("${cursorTransformPoint}") 24 24, move`,
      cursorStyleHandler: cursorStyleHandler,
    });

    acc["p" + index] = newControl;

    return acc;
  }, {});

  return pointControls;
}

function cursorStyleHandler(eventData, control, fabricObject) {
  const canvas = fabricObject.canvas;
  // removeCursor();
  // const svgImg = `<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  // 	 viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve" height="24px" width="24px">
  // <style type="text/css">
  // 	.st0{fill:#10B981;stroke:#10B981;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
  // </style>
  // <polygon class="st0" points="11.5,18 2,24 11.5,30 "/>
  // <polygon class="st0" points="35.5,18.12 45,24.12 35.5,30.12 "/>
  // <polygon class="st0" points="18.25,13.5 24.25,4 30.25,13.5 "/>
  // <polygon class="st0" points="30.25,34.5 24.25,44 18.25,34.5 "/>
  // </svg>`;
  const controlPoint = fabric.util.transformPoint(
    { x: control.x, y: control.y },
    fabric.util.invertTransform(canvas.viewportTransform)
  );
  const currentCursor = canvas.currentCursor;
  if (typeof currentCursor == "string") {
    //currentCursor может быть строкой, это заглушка на время пока курсор загружается из свг.
  } else if (
    (currentCursor && !currentCursor.isPointCursor) ||
    !currentCursor
  ) {
    //если свойство заполнено не тем курсором, или не заполнено совсем
    removeCursor();

    canvas.currentCursor = "loading";
    fabric.loadSVGFromURL(cursorTransformPoint, function (objects, options) {
      const cursorImg = fabric.util.groupSVGElements(objects, options);
      const zoom = canvas.getZoom();
      cursorImg.set({
        left: controlPoint.x,
        top: controlPoint.y,
        originX: "center",
        originY: "center",
        evented: true, //делаем его evented, чтобы отслеживать события мышки и скрывать когда уходим от контрола
        selectable: false,
        hasBorders: false,
        id: new Date().valueOf(),
        isPointCursor: true,
        objectCaching: false,
        scaleX: 1 / zoom,
        scaleY: 1 / zoom,
        hoverCursor: "none",
        lockMovementX: true,
        lockMovementY: true,
        perPixelTargetFind: false,
      });
      cursorImg.on("mouseout", removeCursor);
      cursorImg.on("mouseover", removeCursor);
      canvas.add(cursorImg);
      canvas.currentCursor = cursorImg;
      canvas.renderAll();
    });
  } else {
    //если свойство заполнено нужным курсором

    canvas.currentCursor.set({ left: controlPoint.x, top: controlPoint.y });
    canvas.renderAll();
  }
  return "none";
}

//инициируем события холста, зум, пан
function initCanvasEvents(canvas) {
  //panning the canvas
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, wallsRef, placePoint } = storeToRefs(canvasDataStore);

  canvas.on("mouse:down", function (opt) {
    const evt = opt.e,
      target = opt.target;
    if (evt.button == 2) {
      this.isDragging = true;
      this.selection = false;
      this.lastPosX = evt.clientX;
      this.lastPosY = evt.clientY;
    } else if (
      evt.button == 0 &&
      placePoint.value &&
      (!target || target.type !== "line")
    ) {
      placePoint.value = false;
    }
    if (!target) {
      canvas.setActiveObject(polygonRef.value);
      wallsRef.value.forEach((wall) => {
        if (wall.selected) {
          wall.set({ stroke: wallColor, selected: false });
          removeMeasures();
          removeCursor();
        }
      });
      drawOrthMeasureLines();
      positionAllInputs();
    }
  });
  canvas.on("mouse:move", function (opt) {
    if (this.isDragging) {
      removeCursor();
      const e = opt.e;
      const vpt = this.viewportTransform;
      vpt[4] += e.clientX - this.lastPosX;
      vpt[5] += e.clientY - this.lastPosY;
      this.renderAll();
      this.lastPosX = e.clientX;
      this.lastPosY = e.clientY;
      this.setViewportTransform(vpt);
      positionAllInputs();
    }
  });
  canvas.on("mouse:up", function () {
    // on mouse up we want to recalculate new interaction
    // for all objects, so we call setViewportTransform

    this.setViewportTransform(this.viewportTransform);
    this.isDragging = false;
  });

  //zoom
  canvas.on("mouse:wheel", function (opt) {
    removeCursor();
    const delta = opt.e.deltaY;
    let zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > maxZoom) zoom = maxZoom;
    if (zoom < minZoom) zoom = minZoom;
    canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
    // canvas.setZoom(zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();
    canvas.renderAll();
    positionAllInputs();
  });
  // canvas.on("mouse:out", (opt) => {
  //   if (!opt.target) removeCursor();
  // });
}

export {
  initCanvas,
  generateControls,
  initPolygon,
  getAbsoluteCoords,
  cursorStyleHandler,
};
