/* eslint-disable no-unused-vars */
import { fabric } from "fabric";
import {
  getNextIndexInLoop,
  getPrevIndexInLoop,
  getWallFlattenLine,
  pointAtLength,
  getMouseProjectionOnWall,
} from "@/script/generalUtils";
import {
  getObjectSizeWithStroke,
  anchorWrapper,
  insertNewPoint,
  simplifyPolygon,
  renderInnerContour,
  removeExtraVertices,
} from "@/script/polygonUtils";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import { nextTick } from "vue";
import {
  handleMoveWithWall,
  bringAllObjectstoFront,
  addOpening,
} from "@/script/objectsUtils";
import {
  removeMeasures,
  positionAllInputs,
  drawOrthMeasureLines,
  getMeasureLine,
  drawInnerMeasure,
} from "@/script/measureUtils";
import offsetPolygon from "offset-polygon";
import { initScale, generateControls, wallColor } from "@/script/canvasUtils";
import moveWallCursor from "@/assets/moveWallCursor.svg";
import placePointCursor from "@/assets/placePointCursor.svg";
const selectedColor = "#9DEAC9";
export const ortAllowance = 80; //припуск на прилипание к ортогональной линии

//делает фабриковую линию для стены
function makeWallLine(coords, index) {
  const canvasDataStore = useCanvasDataStore();
  const { stage } = storeToRefs(canvasDataStore);
  const wt = canvasDataStore.wallThickness;
  const newLine = new fabric.Line(coords, {
    fill: "transparent",
    stroke: wallColor,
    strokeWidth: wt,
    hasControls: false,
    hasBorders: false,
    evented: true,
    perPixelTargetFind: true,
    originX: "center",
    originY: "center",
    lockMovementX: true,
    lockMovementY: true,
    wallIndex: index,
    strokeLineCap: "round",
    length: 0,
    selectable: stage.value == 1,
    hoverCursor: "default",
  });
  setWallEvents(newLine);
  return newLine;
}

function makeWalls(needsCentering = true) {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, canvasRef, initPathOffset, wallsRef } =
    storeToRefs(canvasDataStore);
  initPathOffset.value = polygonRef.value.pathOffset;

  removeMeasures();
  const points = polygonRef.value.points;
  const oldWalls = canvasRef.value.getObjects("line").filter((obj) => {
    return obj.wallIndex !== undefined;
  });
  oldWalls.forEach((wall) => {
    canvasRef.value.remove(wall);
  });
  wallsRef.value = [];

  const walls = points.map((point, index) => {
    const nextPoint = points[getNextIndexInLoop(index, points.length)];
    const coords = [
      //проверить нельзя ли сделать трансформ матрицей полигона и получить то же самое
      point.x - initPathOffset.value.x,
      point.y - initPathOffset.value.y,
      nextPoint.x - initPathOffset.value.x,
      nextPoint.y - initPathOffset.value.y,
    ];
    const newLine = makeWallLine(coords, index);

    canvasRef.value.add(newLine);
    wallsRef.value.push(newLine);

    canvasDataStore.updateWallData([index]);
    return newLine;
  }, {});
  //wallsLoaded.value = true;
  const wallsGroup = new fabric.Group(walls, {
    hasControls: false,
    scaleX: initScale,
    scaleY: initScale,
    perPixelTargetFind: true,
    hasBorders: false,
    originX: "center",
    originY: "center",
    selectable: true,
    // flipY: true,
  });

  if (needsCentering) {
    canvasRef.value.centerObject(wallsGroup);
    wallsGroup.setCoords();
    canvasRef.value.centerObject(polygonRef.value);
    polygonRef.value.setCoords();
  }
  const centerPoint = polygonRef.value.getCenterPoint();

  wallsGroup.setPositionByOrigin(centerPoint, "center", "center");
  wallsGroup.bringToFront();
  bringAllObjectstoFront();
}

function handleWallMovement(eventData, transform, x, y) {
  const canvasDataStore = useCanvasDataStore();
  const { canvasRef, polygonRef } = storeToRefs(canvasDataStore);
  removeMeasures();
  WMActionHandler(
    eventData,
    { target: polygonRef.value },
    x,
    y,
    transform.target.wallIndex
  );
  polygonRef.value.setCoords();
  canvasRef.value.renderAll();
}

function applyCursor(mouseEvent, wall, placingObjects) {
  const { canvasRef, polygonRef } = storeToRefs(useCanvasDataStore());
  let cursor = "default";
  const canvas = canvasRef.value;
  const polygon = polygonRef.value;
  const currentCursor = canvas.currentCursor;

  if (placingObjects || wall.isHorizontal || wall.isVertical) {
    cursor = "none";
    const projection = getMouseProjectionOnWall(wall.wallIndex, mouseEvent);
    const matrix = polygon.calcTransformMatrix();
    const newPoint = fabric.util.transformPoint(projection, matrix);
    const left = newPoint.x - polygon.pathOffset.x * initScale;
    const top = newPoint.y - polygon.pathOffset.y * initScale;

    if (typeof currentCursor == "string") {
      //currentCursor может быть строкой, это заглушка на время пока курсор загружается из свг.
    } else if (
      currentCursor &&
      !currentCursor.isPointCursor &&
      currentCursor.isVertical !== wall.isVertical //это условие нужно, чтобы по горизонтальной стене не поехал вдруг горизонтальный курсор
    ) {
      currentCursor.set({ top, left });
      canvas.renderAll();
    } else {
      removeCursor();
      console.log("apply cursor, trying to create a cursor");
      let cursorImage = placePointCursor;
      if (!placingObjects) {
        cursorImage = moveWallCursor;
      }
      //loading нужно из-за того что loadSVGFromURL - асинхронная, а await на нее не вешается.
      //мы ставим в currentCursor заглушку и чекаем типы если хотим подвинуть.
      canvas.currentCursor = "loading";
      fabric.loadSVGFromURL(cursorImage, function (objects, options) {
        console.log("applyCursor, loadingSVG");
        const zoom = canvas.getZoom();
        const cursorImg = fabric.util.groupSVGElements(objects, options);

        cursorImg.set({
          left,
          top,
          originX: "center",
          originY: "center",
          angle: wall.isVertical ? 90 : 0,
          isVertical: !wall.isVertical, //у горизонтальной стены курсор вертикальный и наоборот
          evented: false,
          id: new Date().valueOf(),
          isPointCursor: false,
          objectCaching: false,
          scaleX: 1 / zoom,
          scaleY: 1 / zoom,
        });
        console.log("applyCursor, adding newCursor");
        canvas.add(cursorImg);
        canvas.currentCursor = cursorImg;
      });
    }
    canvas.renderAll();
  }

  wall.set("hoverCursor", cursor);
}
function removeCursor() {
  const { canvasRef } = storeToRefs(useCanvasDataStore());
  const canvas = canvasRef.value;
  // if (canvas.currentCursor) {canvas.remove(canvas.currentCursor);
  // canvas.currentCursor = undefined;}
  canvas.getObjects("group").forEach((gr) => {
    if (gr.isPointCursor !== undefined) canvas.remove(gr);
  });
  canvas.currentCursor = undefined;
}

function WMActionHandler(eventData, transform, x, y, wallIndex) {
  const canvasDataStore = useCanvasDataStore();
  const { initPathOffset, wallsRef } = storeToRefs(canvasDataStore);
  const polygon = transform.target;
  const nextWallIndex = getNextIndexInLoop(wallIndex, polygon.points.length);
  const prevWallIndex = getPrevIndexInLoop(wallIndex, polygon.points.length);
  const mouseLocalPosition = polygon.toLocalPoint(
    new fabric.Point(x, y),
    "center",
    "center"
  );
  const thisWall = wallsRef.value[wallIndex];

  const polygonBaseSize = getObjectSizeWithStroke(polygon);
  const size = polygon._getTransformedDimensions(0, 0);
  if (thisWall.isHorizontal) {
    //меняется только "у" обеих точек

    const yCorrectedForSize =
      (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
      polygon.pathOffset.y;

    const yFinal = bringWallToOrthogonal(
      wallIndex,
      yCorrectedForSize,
      thisWall.isHorizontal
    );
    polygon.points[wallIndex].y = yFinal;
    polygon.points[nextWallIndex].y = yFinal;
    //2000 это половина изначального габарита почему линии съезжают - непонятно
    update3WallLines(
      wallIndex,
      prevWallIndex,
      nextWallIndex,
      null,
      yFinal - initPathOffset.value.y
    );
  } else {
    //меняется только "х" обеих  точек

    const xCorrectedForSize =
      (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
      polygon.pathOffset.x;
    const xFinal = bringWallToOrthogonal(
      wallIndex,
      xCorrectedForSize,
      thisWall.isHorizontal
    );
    polygon.points[wallIndex].x = xFinal;
    polygon.points[nextWallIndex].x = xFinal;
    update3WallLines(
      wallIndex,
      prevWallIndex,
      nextWallIndex,
      xFinal - initPathOffset.value.x,
      null
    );
  }

  return true;
}

//change wallA's x1 and y1 , wallB's x2 and y2
function update2WallLines(wallAIndex, wallBIndex, { x, y }) {
  const canvasDataStore = useCanvasDataStore();
  const { initPathOffset, wallsRef } = storeToRefs(canvasDataStore);

  const _x = x - initPathOffset.value.x;
  const _y = y - initPathOffset.value.y;
  const wallA = getWallLineByIndex(wallAIndex),
    wallB = getWallLineByIndex(wallBIndex);

  wallA.set({ x1: _x, y1: _y });
  //нужно для того чтобы обновить bounding box
  wallA.setCoords();
  wallB.set({ x2: _x, y2: _y });
  wallB.setCoords();

  renderInnerContour(false);
  try {
    handleMoveWithWall([wallAIndex, wallBIndex]);
  } catch (e) {
    console.log(e);
  }
  canvasDataStore.updateWallData([wallAIndex, wallBIndex]);
  drawInnerMeasure(wallsRef.value[wallAIndex]);
  drawInnerMeasure(wallsRef.value[wallBIndex]);
  nextTick(positionAllInputs);
}

//change this wall's both ends, wallprev's x2=>x(from param) y2=>y1(from param),
//wallB's x1=>x2(from param), y1=>y2(from param)
function update3WallLines(thisWallIndex, prevWallIndex, nextWallIndex, x, y) {
  const { wallsRef } = storeToRefs(useCanvasDataStore());
  const thisWall = getWallLineByIndex(thisWallIndex),
    nextWall = getWallLineByIndex(nextWallIndex),
    prevWall = getWallLineByIndex(prevWallIndex);
  if (x) {
    thisWall.set({ x1: x, x2: x });
    nextWall.set({ x1: x });
    prevWall.set({ x2: x });
  } else if (y) {
    thisWall.set({ y1: y, y2: y });
    nextWall.set({ y1: y });
    prevWall.set({ y2: y });
  }

  thisWall.setCoords();
  nextWall.setCoords();
  prevWall.setCoords();

  renderInnerContour(false);
  try {
    handleMoveWithWall([thisWallIndex, prevWallIndex, nextWallIndex]);
  } catch (e) {
    console.log(e);
  }

  const { updateWallData } = useCanvasDataStore();
  updateWallData([thisWallIndex, prevWallIndex, nextWallIndex]);
  // drawInnerMeasure(wallsRef.value[thisWallIndex]);
  drawInnerMeasure(wallsRef.value[prevWallIndex]);
  drawInnerMeasure(wallsRef.value[thisWallIndex]);
  drawInnerMeasure(wallsRef.value[nextWallIndex]);
  nextTick(positionAllInputs);
}
//next is clockwise, previous - counterclockwise
function getAdjacentPoints(index) {
  const canvasDataStore = useCanvasDataStore(),
    { polygonRef } = storeToRefs(canvasDataStore),
    offset = polygonRef.value.pathOffset,
    points = polygonRef.value.points,
    nextIndex = getNextIndexInLoop(index, points.length),
    prevIndex = getPrevIndexInLoop(index, points.length);

  return {
    nextX: points[nextIndex].x - offset.x,
    nextY: points[nextIndex].y - offset.y,
    prevX: points[prevIndex].x - offset.x,
    prevY: points[prevIndex].y - offset.y,
  };
}

//вход: индекс точки, координаты от пользователя,
function bringToOrthogonal(index, { x, y }) {
  const { nextX, nextY, prevX, prevY } = getAdjacentPoints(index);

  let newX = x,
    newY = y;

  if (isOrtEnough(x, nextX)) {
    newX = nextX;
  } else if (isOrtEnough(x, prevX)) {
    newX = prevX;
  }
  if (isOrtEnough(y, nextY)) {
    newY = nextY;
  } else if (isOrtEnough(y, prevY)) {
    newY = prevY;
  }

  return { newX, newY };
}

function bringWallToOrthogonal(pointIndex, coord, isHorizontal) {
  const canvasDataStore = useCanvasDataStore(),
    { polygonRef } = storeToRefs(canvasDataStore),
    points = polygonRef.value.points;
  let newCoord = coord;
  if (points.length < 5) {
    return newCoord;
  }
  //берем предыдущую точку, будем сравнивать с нею
  const prevPoint = points[getPrevIndexInLoop(pointIndex, points.length)];
  //берем также точку через 1 от текущей, так просто следующая точка это конец нашей стены
  const nextNextPoint =
    points[
      getNextIndexInLoop(
        getNextIndexInLoop(pointIndex, points.length),
        points.length
      )
    ];
  if (isHorizontal) {
    //check y
    if (isOrtEnough(coord, nextNextPoint.y)) {
      newCoord = nextNextPoint.y;
    } else if (isOrtEnough(coord, prevPoint.y)) {
      newCoord = prevPoint.y;
    }
    //отобрать все горизонтальные стены, сравнивать с ними на пример такого же у
    else {
      newCoord = checkAgainstOrthoWalls(
        points,
        isHorizontal,
        coord,
        pointIndex
      );
    }
  } else {
    //check x
    if (isOrtEnough(coord, nextNextPoint.x)) {
      newCoord = nextNextPoint.x;
    } else if (isOrtEnough(coord, prevPoint.x)) {
      newCoord = prevPoint.x;
    }
    //отобрать все вертикальные стены, сравнивать с ними на пример такого же х
    else {
      newCoord = checkAgainstOrthoWalls(
        points,
        isHorizontal,
        coord,
        pointIndex
      );
    }
  }
  return newCoord;
}

function checkAgainstOrthoWalls(points, horizontal, coord, currentWallIndex) {
  let newCoord = coord;
  const orthoWalls = points.reduce((acc, currentPoint, index) => {
    if (currentWallIndex !== index) {
      //игноригуем стену, которую сейчас двигаем
      const lineOrthagonality = checkOrthogonal(index);
      if (
        horizontal & lineOrthagonality.isHorizontal ||
        !horizontal & lineOrthagonality.isVertical
      ) {
        //получаем массив точек полигона, которые являются началом ортогональных стен с нужной нам ориентацией.
        //две точки нам не нужны, потому что у них заведомо одинаковая одна из координат - та, которую мы и будем сравнивать
        acc.push(currentPoint);
      }
    }
    return acc;
  }, []);

  orthoWalls.forEach((point) => {
    if (horizontal & isOrtEnough(coord, point.y)) {
      newCoord = point.y;
    } else if (!horizontal & isOrtEnough(coord, point.x)) {
      newCoord = point.x;
    }
  });
  return newCoord;
}

function isOrtEnough(a, b) {
  return Math.abs(a - b) <= ortAllowance;
}

function checkOrthogonal(wallIndex) {
  const { x1, x2, y1, y2 } = getWallLineByIndex(wallIndex);
  return {
    isVertical: Math.round(x1) === Math.round(x2),
    isHorizontal: Math.round(y1) === Math.round(y2),
  };
}

function getWallLineByIndex(index) {
  const canvasDataStore = useCanvasDataStore();
  const { wallsRef } = storeToRefs(canvasDataStore);
  const res = wallsRef.value[index];
  return res;
}

function setWallEvents(wall) {
  const canvasDataStore = useCanvasDataStore();
  const {
    placePoint,
    canvasRef,
    polygonRef,
    wallsRef,
    stage,
    //placeCommunications,
  } = storeToRefs(canvasDataStore);
  const canvas = canvasRef.value;

  wall.on("mouseover", function (opt) {
    // const target = e.target;
    if (stage.value == 1) {
      this.set("stroke", selectedColor);

      canvas.renderAll();
    }
  });

  wall.on("mouseout", function (e) {
    if (stage.value == 1) {
      if (e.target && !e.target.selected) {
        this.set("stroke", wallColor);
        canvas.renderAll();
      }
      removeCursor();
    }
  });

  wall.on("mousedown", function (opt) {
    const evt = opt.e;
    if (stage.value == 1) {
      if (evt.button == 0 && !placePoint.value) {
        if ((this.isHorizontal || this.isVertical) && stage.value == 1) {
          this.wallTransformationInit = true;
        }
      }
    }
  });
  wall.on("mousemove", function (opt) {
    if (stage.value == 1) {
      if (this.wallTransformationInit && opt.e.buttons == 1) {
        canvas.setActiveObject(this);
        const index = this.wallIndex,
          { x, y } = canvasRef.value.getPointer(opt.e);
        const wrappedHandling = anchorWrapper(
          getPrevIndexInLoop(index, polygonRef.value.points.length),
          handleWallMovement
        );
        wrappedHandling(opt.e, opt, x, y);

        this.wallTransformed = true;
      }
      applyCursor(opt.e, this, placePoint.value);
    }
  });

  wall.on("mouseup", function (opt) {
    const evt = opt.e;
    if (evt.button == 0) {
      const pointer = canvasRef.value.getPointer(evt);
      if (placePoint.value) {
        insertNewPoint(this.wallIndex, pointer.x, pointer.y);
      } else {
        placePoint.value = false;
        if (this.wallTransformed) {
          this.wallTransformed = false;
          simplifyPolygon();
        }
        this.wallTransformationInit = false;
      }
    }
  });

  wall.on("selected", function (opt) {
    const target = opt.target;
    if (!target) return;
    target.set({ stroke: selectedColor, selected: true });
    wallsRef.value.forEach((wall) => {
      if (wall.wallIndex !== target.wallIndex && wall.selected) {
        wall.set({ stroke: wallColor, selected: false });
        removeCursor();
      }
    });
    removeMeasures();
    drawInnerMeasure(target);

    //canvas.setActiveObject(polygonRef.value);
  });
  // wall.on("deselected", function (opt) {
  //   const target = opt.target;
  //   if (!target) return;
  //   target.set({ stroke: wallColor, selected: false });

  //   // canvas.renderAll();
  //   removeMeasures();
  //   positionAllInputs();
  // });
}

//wallIndex - массив индексов стен, к которым относится эта размерная линия и соответственно инпут
//leftButton - показывает какую кнопку нажали. в зависимости от этого для каждой стены будем менять либо точку начала либо конца
function changeWallLength(wallIndex, newLength, leftButton) {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, innerPolygon, innerPolygon2, innerPolygon3, canvasRef } =
    storeToRefs(canvasDataStore);
  const innerPoly = innerPolygon.value;
  const innerPoly2 = innerPolygon2.value;
  const innerPoly3 = innerPolygon3.value;
  const points = polygonRef.value.points;

  //обертка нужна для того чтобы полигон не прыгал по холсту
  /////wrapperStart
  const polygon = polygonRef.value;
  const absolutePoint = fabric.util.transformPoint(
    {
      x: points[wallIndex].x - polygon.pathOffset.x,
      y: points[wallIndex].y - polygon.pathOffset.y,
    },
    polygon.calcTransformMatrix()
  );

  ///собственно изменение точек

  changePoints(wallIndex, newLength, leftButton);

  ///anchorWrapper continuation

  polygon._setPositionDimensions({});
  const polygonBaseSize = getObjectSizeWithStroke(polygon);
  const newX = (points[wallIndex].x - polygon.pathOffset.x) / polygonBaseSize.x;
  const newY = (points[wallIndex].y - polygon.pathOffset.y) / polygonBaseSize.y;
  polygon.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
  // polygon.setPositionByOrigin(polygon.getCenterPoint(), "center", "center");
  polygon.setCoords();

  innerPoly._setPositionDimensions({});
  innerPoly2._setPositionDimensions({});
  innerPoly3._setPositionDimensions({});

  //anchorWrapper end
  makeWalls();
  try {
    handleMoveWithWall(points.map((p, i) => i));
  } catch (e) {
    console.log(e);
  }
  const centerPoint = polygon.getCenterPoint();
  innerPoly.setPositionByOrigin(centerPoint, "center", "center");
  innerPoly2.setPositionByOrigin(centerPoint, "center", "center");
  innerPoly3.setPositionByOrigin(centerPoint, "center", "center");

  drawOrthMeasureLines();
  positionAllInputs();
  canvasRef.value.setActiveObject(polygon);
}

function changePoints(wallIndex, newLength, leftButton) {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, innerPolygon, wallsRef } = storeToRefs(canvasDataStore);
  const wt = canvasDataStore.wallThickness;
  const innerPoly = innerPolygon.value;
  let innerPoints = innerPoly.points;
  let points = polygonRef.value.points;
  //получим стены, к которым привязана данная размерная линия
  const wallIndexes = getMeasureLine(wallIndex).index;
  let changedPoints = [];
  wallIndexes.forEach((wallIndex) => {
    const wall = wallsRef.value[wallIndex];
    let wallSegment = getWallFlattenLine(wallIndex, innerPoints, true);
    let pointIndex = getNextIndexInLoop(wallIndex, innerPoints.length);
    let adjacentPointIndex = getNextIndexInLoop(pointIndex, innerPoints.length);
    let adjacentWall;
    const addToEnd = wall.reverse ? leftButton : !leftButton;
    if (!addToEnd) {
      //если добавляем к началу, то перевернем наш сегмент, чтобы нашлась точка на нужной длине от нового начала
      //а так же менять будем точку полигона соответствующую индексу стены
      wallSegment = wallSegment.reverse();
      pointIndex = wallIndex;
      adjacentPointIndex = getPrevIndexInLoop(pointIndex, innerPoints.length);
      adjacentWall = wallsRef.value[adjacentPointIndex];
    } else {
      adjacentWall = wallsRef.value[pointIndex];
    }

    //если уже обработали эту точку - едем дальше
    if (changedPoints.includes(pointIndex)) return;

    //обработаем эту точку
    changedPoints.push(pointIndex);
    const newPoint = pointAtLength(
      wallSegment.ps.x,
      wallSegment.ps.y,
      wallSegment.pe.x,
      wallSegment.pe.y,
      newLength
    );

    //а так же смежную в том же направлении
    if (wall.isHorizontal && adjacentWall.isVertical) {
      innerPoints[adjacentPointIndex].x = newPoint.x;
      changedPoints.push(adjacentPointIndex);
    } else if (wall.isVertical && adjacentWall.isHorizontal) {
      innerPoints[adjacentPointIndex].y = newPoint.y;
      changedPoints.push(adjacentPointIndex);
    }

    innerPoints[pointIndex] = newPoint;
  });
  const { pointsModified, newPoints } = removeExtraVertices(innerPoints);
  if (pointsModified) {
    innerPolygon.value.points = newPoints;
  }
  const newOuterPoints = offsetPolygon(innerPolygon.value.points, wt / 2, 0);
  polygonRef.value.points = newOuterPoints;

  if (pointsModified) {
    polygonRef.value.controls = generateControls(polygonRef.value.points);
    polygonRef.value.setCoords();
  }
}
export {
  applyCursor,
  handleWallMovement,
  makeWalls,
  update2WallLines,
  bringToOrthogonal,
  checkOrthogonal,
  getWallLineByIndex,
  isOrtEnough,
  changeWallLength,
  removeCursor,
};
