<template>
  <div class="input-group">
    <label :for="label">{{ label }}</label>
    <InputNumber
      :id="label"
      v-model="modelValue"
      suffix=" мм"
      showButtons
      buttonLayout="horizontal"
      :step="100"
      inputClass="input-numeric"
      @update:modelValue="$emit('input')"
      :disabled="disabled"
    >
      <template #incrementbuttonicon>
        <span class="pi pi-plus" />
      </template>
      <template #decrementbuttonicon>
        <span class="pi pi-minus" />
      </template>
    </InputNumber>
  </div>
</template>
<script setup>
import { defineProps, toRefs, defineModel, defineEmits } from "vue";
defineEmits(["input"]);
const props = defineProps(["label", "disabled"]);
const { label, disabled } = toRefs(props);
const modelValue = defineModel();

// const localValue = computed(() => props.modelValue);
</script>

<style>
.input-numeric {
  width: 100%;
}
.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem;
}
</style>
<style scoped></style>
