<template>
  <div class="opening-select">
    <div v-for="opening in openingOptions" :key="opening.id">
      <button
        class="opening-select-btn"
        type="button"
        @click="
          opening.action();
          $emit('changeOpening');
        "
      >
        <img
          :src="opening.src"
          :id="opening.id"
          class="opening-img"
          :class="{ active: opening.condition() }"
          :alt="opening.label"
        />
        <p class="opening-label">{{ opening.label }}</p>
      </button>
    </div>
  </div>
</template>
<script setup>
import archImg from "@/assets/openings/arch.svg";
import doorSingleLeftImg from "@/assets/openings/doorSingleLeft.svg";
import doorSingleRightImg from "@/assets/openings/doorSingleRight.svg";
import doorDoubleImg from "@/assets/openings/doorDouble.svg";
import windowSingleRightImg from "@/assets/openings/windowSingleRight.svg";
import windowSingleLeftImg from "@/assets/openings/windowSingleLeft.svg";
import windowDoubleBothImg from "@/assets/openings/windowDoubleBoth.svg";
import windowDoubleLeftImg from "@/assets/openings/windowDoubleLeft.svg";
import windowDoubleRightImg from "@/assets/openings/windowDoubleRight.svg";
import windowSingleShutImg from "@/assets/openings/windowSingleShut.svg";

import { defineProps, toRefs, computed } from "vue";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
const props = defineProps(["is"]);
const { is } = toRefs(props);
const canvasDataStore = useCanvasDataStore();
const { activeObject } = storeToRefs(canvasDataStore);

const openingOptions = computed(() => {
  const options = [];
  const actObj = activeObject.value;
  if (is.value.window) {
    options.push({
      src: windowDoubleBothImg,
      id: "windowDoubleBoth",
      label: "Обе створки открываются",
      condition: () => {
        return actObj.frameType === 1;
      },
      action: () => {
        actObj.single = false;

        actObj.frameType = 1;
      },
    });

    options.push({
      src: windowDoubleLeftImg,
      id: "windowDoubleLeft",
      label: "Правая створка глухая",
      condition: () => {
        return actObj.frameType === 2;
      },
      action: () => {
        actObj.single = false;
        actObj.frameType = 2;
      },
    });

    options.push({
      src: windowDoubleRightImg,
      id: "windowDoubleRigh",
      label: "Левая створка глухая",

      condition: () => {
        return actObj.frameType === 3;
      },
      action: () => {
        actObj.single = false;
        actObj.frameType = 3;
      },
    });

    options.push({
      src: windowSingleRightImg,
      id: "windowSingleRight",
      label: "Открывается направо",

      condition: () => {
        return actObj.single && actObj.opensToRight;
      },
      action: () => {
        actObj.single = true;
        actObj.frameType = undefined;
        actObj.opensToRight = true;
      },
    });

    options.push({
      src: windowSingleLeftImg,
      id: "windowSingleLeft",
      label: "Открывается налево",

      condition: () => {
        return (
          actObj.single &&
          actObj.opensToRight === false &&
          actObj.frameType === undefined
        );
      },
      action: () => {
        actObj.single = true;
        actObj.frameType = undefined;
        actObj.opensToRight = false;
      },
    });

    options.push({
      src: windowSingleShutImg,
      id: "windowSingleShut",
      label: "Глухое",

      condition: () => {
        return actObj.single && actObj.frameType === 0;
      },
      action: () => {
        actObj.single = true;
        actObj.frameType = 0;
      },
    });
  } else if (is.value.door) {
    options.push({
      src: doorDoubleImg,
      id: "doorDouble",
      label: "Двустворчатая",

      condition: () => {
        return !actObj.single;
      },
      action: () => {
        actObj.single = false;
        actObj.opensToRight = false;
      },
    });
    options.push({
      src: doorSingleLeftImg,
      id: "doorSingleLeft",
      label: "Открывается налево",

      condition: () => {
        return actObj.single && !actObj.opensToRight;
      },
      action: () => {
        actObj.single = true;
        actObj.opensToRight = false;
      },
    });
    options.push({
      src: doorSingleRightImg,
      id: "doorSingleRight",
      label: "Открывается направо",

      condition: () => {
        return actObj.single && actObj.opensToRight;
      },
      action: () => {
        actObj.single = true;
        actObj.opensToRight = true;
      },
    });
  } else if (is.value.arch) {
    options.push({
      src: archImg,
      id: "arch",
      label: "Арка",

      action: () => {},
      condition: () => {
        return true;
      },
    });
  }

  return options;
});
</script>
<style>
.opening-select {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 12px;
}
.opening-select-btn {
  border: none;
  background-color: unset;
  cursor: pointer;
}
.opening-img {
  height: 140px;
  border: 2px solid transparent;
  border-radius: 6px;
  padding: 0.5rem;
  display: block;
}
.opening-img.active {
  border: 2px solid #10b981;
}
.opening-label {
  word-wrap: break-word;
}
</style>
