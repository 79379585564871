<template>
  <form v-show="activeObject.id" class="active-object-form">
    <PrimeButton
      icon="pi pi-times"
      text
      severity="secondary"
      aria-label="Закрыть"
      :class="'close-btn'"
      @click="discardActiveObject"
    />
    <SelectButton
      v-if="is.com"
      v-model="activeObject.communicationType"
      :options="objectTypeOptions"
      optionLabel="name"
      optionValue="value"
      data-key="value"
      :allowEmpty="false"
      @update:model-value="changeCommunication()"
    >
      <template #option="slotProps">
        <!-- <i class="pi" v-tooltip.top="slotProps.option.name"
          ><component
            v-tooltip.top="slotProps.option.name"
            :is="slotProps.option.component"
        /></i> -->
        <i class="pi" v-tooltip.top="slotProps.option.name"
          ><img :src="slotProps.option.icon" :alt="slotProps.option.name" />
        </i>
        <!-- <i class="pi" v-html="svg" v-tooltip.top="slotProps.option.name"></i> -->
      </template>
    </SelectButton>

    <SelectButton
      v-else
      v-model="activeObject.openingType"
      :options="objectTypeOptions"
      optionLabel="name"
      optionValue="value"
      @update:model-value="changeOpening(true)"
      :allowEmpty="false"
    />

    <ScrollPanel v-if="!is.com" style="height: 300px; width: 100%">
      <OpeningSelect :is="is" @changeOpening="changeOpening()" />
    </ScrollPanel>

    <PrimeFieldset legend="Параметры">
      <ObjectInputNumeric
        v-if="!is.com"
        label="Ширина:"
        @input="applyNewWidth"
        v-model="activeObject.widthClean"
      />
      <ObjectInputNumeric
        v-if="!is.com"
        label="Высота:"
        v-model="activeObject.openingHeight"
      />
      <ObjectInputNumeric
        v-if="is.window || is.com"
        label="Высота от пола:"
        v-model="activeObject.distanceFromFloor"
      />
      <div class="input-group" v-if="is.door">
        <label for="opensInside" class="radio-label">Способ открывания:</label>
        <SelectButton
          v-model="activeObject.opensInside"
          :options="opensInsideOptions"
          optionLabel="name"
          optionValue="value"
          :allowEmpty="false"
          @update:model-value="changeOpening()"
        />
      </div>
    </PrimeFieldset>

    <div class="form-actions">
      <PrimeButton
        severity="danger"
        icon="pi pi-trash"
        label="Удалить"
        @click="deleteObject"
      />
    </div>
  </form>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import ObjectInputNumeric from "./ObjectInputNumeric.vue";
import { computed, ref } from "vue";
import {
  makeOpening,
  openingsDefault,
  communicationsDefault,
  makeComMarker,
} from "@/script/objectsUtils";
import { drawOrthMeasureLines, positionAllInputs } from "@/script/measureUtils";
import OpeningSelect from "@/components/OpeningSelect.vue";

const canvasDataStore = useCanvasDataStore();
const {
  activeObject,
  canvasRef,
  openingsRef,
  wallsRef,
  stage,
  communicationsRef,
} = storeToRefs(canvasDataStore);

const is = computed(() => {
  const openingType = activeObject.value.openingType;
  const communicationType = activeObject.value.communicationType;
  return {
    door: openingType === "door",
    window: openingType === "window",
    arch: openingType === "arch",
    com: communicationType !== undefined,
  };
});

//здесь наполняем варианты проемов/коммуникаций.
const objectTypeOptions = computed(() => {
  let options;
  const objectsDefault =
    stage.value === 1 ? openingsDefault : communicationsDefault;

  options = Object.entries(objectsDefault).map(([key, value]) => {
    return {
      name: value.label,
      icon: value.icon,
      value: key,
    };
  });

  return options;
});
const opensInsideOptions = computed(() => {
  return [
    {
      name: "Внутрь",
      value: true,
    },
    {
      name: "Наружу",
      value: false,
    },
  ];
});

function deleteObject() {
  if (is.value.com) {
    //удаляем из хранилища
    communicationsRef.value = communicationsRef.value.filter((com) => {
      return com.id !== activeObject.value.id;
    });
    //удаляем с холста иконку коммуникаций
    canvasRef.value.remove(activeObject.value.icon);
  } else {
    //удаляем из хранилища
    openingsRef.value = openingsRef.value.filter((opening) => {
      return opening.id !== activeObject.value.id;
    });
  }
  //удаляем с холста сам объект
  canvasRef.value.remove(activeObject.value);
  drawOrthMeasureLines();
  positionAllInputs();
}

function applyNewWidth() {
  const newOpening = makeOpening({
    ...activeObject.value,
  });

  replaceOldOpening(newOpening);
}
//пересчитаем расстояние до стен после изменения ширины объекта
function recalcBothDistances() {
  const opening = activeObject.value;
  //пропускаем, если объект не на стене
  if (opening.attachedToWall === undefined) return;
  const wallLength = wallsRef.value[opening.attachedToWall].length;
  //найдем старую ширину через еще не измененные расстояния до стен и ширину
  const oldWidth =
    wallLength - opening.distanceToLeft - opening.distanceToRight;
  const d = opening.widthClean - oldWidth;
  //поскольку центр неизменный, пол-дельты отнимаем от расстояний до стен.
  opening.distanceToLeft -= d / 2;
  opening.distanceToRight -= d / 2;
}

function discardActiveObject() {
  activeObject.value = {};
  drawOrthMeasureLines();
  positionAllInputs();
}

//applyDefault - показатель того, что происходит смена типа проема,
//и надо применить стандартные параметры
function changeOpening(applyDefault = false) {
  const actObj = activeObject.value;

  if (applyDefault) {
    //сначала заменим некоторые параметры на стандартные
    const defaults = openingsDefault[actObj.openingType];
    actObj.opensInside = defaults.opensInside;
    actObj.openingHeight = defaults.openingHeight;
    actObj.single = defaults.single;
    actObj.opensToRight = defaults.opensToRight;
    actObj.distanceFromFloor = defaults.distanceFromFloor;
    actObj.widthClean = defaults.width;
    actObj.frameType = defaults.frameType;
  }
  //потом пересоздадим проем
  const newOpening = makeOpening({
    ...actObj,
  });
  //заменим старый на новый
  replaceOldOpening(newOpening);
}

function changeCommunication() {
  const actObj = activeObject.value;
  const canvas = canvasRef.value;
  //уберем старый маркер
  communicationsRef.value = communicationsRef.value.filter(
    (op) => op.id !== actObj.id
  );
  //уберем с холста иконку и сам маркер
  canvas.remove(activeObject.value.icon, activeObject.value);
  //пересоздадим
  makeComMarker({ ...actObj });
}

function replaceOldOpening(newOpening) {
  const canvas = canvasRef.value;
  //уберем из хранилища старый проем
  openingsRef.value = openingsRef.value.filter((op) => op.id !== newOpening.id);
  //уберем с холста
  canvas.remove(activeObject.value);
  //добавим новый
  canvas.add(newOpening);
  //добавим новый в хранилище
  openingsRef.value.push(newOpening);
  //поставим как активный
  activeObject.value = newOpening;
  //пересчитаем расстояние до стен
  recalcBothDistances();
  //добавим в selection новый проем, чтобы сработали штуки на событие selected
  canvasRef.value.setActiveObject(newOpening);
}
</script>
<style>
.active-object-form {
  border: 1px solid gray;
  border-radius: 6px;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 3rem;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.form-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}
.form-heading {
  margin-bottom: 0;
}
.radio-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-evenly;
}
.radio-subgroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}
.radio-label {
  margin-left: 0.5rem;
}
.close-btn {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 10;
  line-height: 1;
}
.radio-img {
  width: 50px;
}
.p-selectbutton {
  display: flex;
  flex-wrap: wrap;
}
/* сделаем недоступную опцию такую же как обычную цветом, чтобы не смущать народ */
.p-selectbutton .p-button.p-disabled {
  color: #0f172a;
}
</style>
