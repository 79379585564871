/* eslint-disable no-unused-vars */
import { fabric } from "fabric";
import { getNextIndexInLoop } from "@/script/generalUtils";
import { generateControls } from "@/script/canvasUtils";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import { line, point } from "@flatten-js/core";
import offsetPolygon from "offset-polygon";
import { updateStick, handleMoveWithWall } from "@/script/objectsUtils";
import {
  drawOrthMeasureLines,
  removeMeasures,
  positionAllInputs,
} from "@/script/measureUtils";
import { nextTick } from "vue";
import {
  bringToOrthogonal,
  update2WallLines,
  makeWalls,
  removeCursor,
} from "@/script/wallsUtils";
import { cursorStyleHandler } from "@/script/canvasUtils";
//получить координаты концов стены по индексу точки. вторая точка - следующая в массиве
function getLineCoordsByIndex(index, points) {
  const x1 = points[index].x,
    y1 = points[index].y,
    nextIndex = getNextIndexInLoop(index, points.length),
    x2 = points[nextIndex].x,
    y2 = points[nextIndex].y;
  return { x1, y1, x2, y2 };
}

// define a function that can locate the controls.
// this function will be used both for drawing and for interaction.
function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
    y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
  const controlPosition = fabric.util.transformPoint(
    { x: x, y: y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  );
  this.x = controlPosition.x;
  this.y = controlPosition.y;
  return controlPosition;
}

function getObjectSizeWithStroke(object) {
  const stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
}

// define a function that will define what the control does
// this function will be called on every mouse move after a control has been
// clicked and is being dragged.
// The function receive as argument the mouse event, the current trasnform object
// and the current position in canvas coordinate
// transform.target is a reference to the current object being transformed,
function actionHandler(eventData, transform, x, y) {
  removeMeasures();
  removeCursor();
  const polygon = transform.target;
  const currentControl = polygon.controls[polygon.__corner],
    currentPointIndex = currentControl.pointIndex,
    mouseLocalPosition = polygon.toLocalPoint(
      new fabric.Point(x, y),
      "center",
      "center"
    );
  polygon.canvas.setCursor("none");
  //   cursorStyleHandler(undefined, polygon.__corner, polygon)
  // );
  const polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    xCorrectedForSize = (mouseLocalPosition.x * polygonBaseSize.x) / size.x,
    yCorrectedForSize = (mouseLocalPosition.y * polygonBaseSize.y) / size.y;
  let newX, newY;
  if (polygon.points.length < 4) {
    newX = xCorrectedForSize;
    newY = yCorrectedForSize;
  } else {
    const orthogonized = bringToOrthogonal(currentPointIndex, {
      x: xCorrectedForSize,
      y: yCorrectedForSize,
    });
    {
      newX = orthogonized.newX;
      newY = orthogonized.newY;
    }
  }

  const finalPointPosition = {
    x: newX + polygon.pathOffset.x,
    y: newY + polygon.pathOffset.y,
  };
  polygon.points[currentPointIndex] = finalPointPosition;
  update2WallLines(
    currentControl.adjacentWallAIndex,
    currentControl.adjacentWallBIndex,
    finalPointPosition
  );

  return true;
}

// define a function that can keep the polygon in the same position when we change its
// width/height/top/left.
function anchorWrapper(anchorIndex, fn) {
  return function (eventData, transform, x, y) {
    const canvasDataStore = useCanvasDataStore();
    const { polygonRef } = storeToRefs(canvasDataStore);
    const fabricObject = polygonRef.value;
    const absolutePoint = fabric.util.transformPoint(
      {
        x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
        y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
      },
      fabricObject.calcTransformMatrix()
    );
    const actionPerformed = fn(eventData, transform, x, y);

    fabricObject._setPositionDimensions({});
    const polygonBaseSize = getObjectSizeWithStroke(fabricObject);
    const newX =
      (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) /
      polygonBaseSize.x;
    const newY =
      (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) /
      polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  };
}

//проходимся по точкам полигона, сравниваем не лежит ли точка между своими соседями
//собираем новый массив точек без лишних
//если какие-то точки убрались - делаем заново контролы и стены
function simplifyPolygon() {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, canvasRef, placePoint } = storeToRefs(canvasDataStore);
  const polygon = polygonRef.value;
  if (placePoint.value) return;
  const points = polygon.points;
  const { pointsModified, newPoints } = removeExtraVertices(points);
  if (pointsModified) {
    polygon.points = newPoints;
    polygon.controls = generateControls(polygon.points);
    renderInnerContour(false);
    makeWalls(false);
    updateStick();
    try {
      handleMoveWithWall(points.map((p, i) => i));
    } catch (e) {
      console.log(e);
    }
  }
  drawOrthMeasureLines();
  nextTick(positionAllInputs);

  canvasRef.value.setActiveObject(polygon);
}

//убираем лишние точки
function removeExtraVertices(points) {
  const newPoints = [points[0]];
  let pointsModified = false;

  //минимум 4 точки оставляем, не схлапываем
  if (points.length < 5) {
    return { pointsModified, points };
  }
  for (let i = 1; i < points.length; i++) {
    const thisPoint = point(points[i].x, points[i].y),
      prevPoint = newPoints[newPoints.length - 1],
      A = point(prevPoint.x, prevPoint.y),
      nextPoint = points[getNextIndexInLoop(i, points.length)],
      B = point(nextPoint.x, nextPoint.y),
      AB = line(A, B);

    if (!AB.contains(thisPoint)) {
      newPoints.push(points[i]);
    } else {
      pointsModified = true;
    }
  }
  //проверка не лежит ли нулевой элемент м/у последним и первым
  const zeroPoint = point(newPoints[0].x, newPoints[0].y),
    A = point(
      newPoints[newPoints.length - 1].x,
      newPoints[newPoints.length - 1].y
    ),
    B = point(newPoints[1].x, newPoints[1].y),
    AB = line(A, B);
  if (AB.contains(zeroPoint)) {
    newPoints.splice(0, 1);
    pointsModified = true;
  }
  return { pointsModified, newPoints };
}

//берем координаты стены, опускаем на нее проекцию из положения мышки (переведенного в локальные координаты),
//получаем точку на стене, делаем из нее новую точку полигона,
//пересоздаем контролы, перерисовываем стенки.
function insertNewPoint(wallIndex, x, y) {
  const canvasDataStore = useCanvasDataStore(),
    { polygonRef, canvasRef } = storeToRefs(canvasDataStore),
    polygon = polygonRef.value,
    mouseLocalPosition = polygon.toLocalPoint(
      new fabric.Point(x, y),
      "center",
      "center"
    );

  const polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    xFinal =
      (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
      polygon.pathOffset.x,
    yFinal =
      (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
      polygon.pathOffset.y;
  const points = polygonRef.value.points,
    thisPoint = points[wallIndex],
    nextPoint = points[getNextIndexInLoop(wallIndex, points.length)];

  const pointA = point(thisPoint.x, thisPoint.y),
    pointB = point(nextPoint.x, nextPoint.y),
    pointC = point(xFinal, yFinal),
    AB = line(pointA, pointB),
    projection = pointC.projectionOn(AB);

  polygonRef.value.points.splice(wallIndex + 1, 0, {
    x: projection.x,
    y: projection.y,
  });
  polygonRef.value.controls = generateControls(polygonRef.value.points);
  renderInnerContour(false);
  makeWalls(false);
  drawOrthMeasureLines();
  nextTick(positionAllInputs);
  updateStick();
  try {
    handleMoveWithWall(points.map((p, i) => i));
  } catch (e) {
    console.log(e);
  }

  canvasRef.value.setActiveObject(polygon);
  removeCursor();
}

function renderInnerContour(needsCentering = true) {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, innerPolygon, innerPolygon2, innerPolygon3, canvasRef } =
    storeToRefs(canvasDataStore);
  const wt = canvasDataStore.wallThickness;
  const polygon = polygonRef.value;
  const points = polygon.points;
  const innerPoly = innerPolygon.value;
  innerPoly.points = offsetPolygon(points, -wt / 2, 0);

  //когда добавляем новую точку, алгоритм который делает оффсет создает одну лишнюю точку, дублирующую созданную.
  //убираем ее
  if (innerPoly.points.length > points.length) {
    innerPoly.points = innerPoly.points.filter(function (item, index, arr) {
      return (
        !index || !(item.x == arr[index - 1].x && item.y == arr[index - 1].y)
      );
    });
  }
  innerPolygon2.value.points = innerPolygon3.value.points = innerPoly.points;

  innerPoly.setCoords();
  innerPoly.bringToFront();
  canvasRef.value.renderAll();
}

export {
  getLineCoordsByIndex,
  polygonPositionHandler,
  getObjectSizeWithStroke,
  actionHandler,
  anchorWrapper,
  simplifyPolygon,
  insertNewPoint,
  renderInnerContour,
  removeExtraVertices,
};
