import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-green/theme.css";
import Button from "primevue/button";
import Slider from "primevue/slider";
import InputNumber from "primevue/inputnumber";
import "primeicons/primeicons.css";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputSwitch from "primevue/inputswitch";
import SplitButton from "primevue/splitbutton";
import RadioButton from "primevue/radiobutton";
import Fieldset from "primevue/fieldset";
import Toolbar from "primevue/toolbar";
import Textarea from "primevue/textarea";
import ConfirmDialog from "primevue/confirmdialog";
// import DynamicDialog from "primevue/dynamicdialog";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ConfirmationService from "primevue/confirmationservice";
// import DialogService from "primevue/dialogservice";
import Tooltip from "primevue/tooltip";
import Image from "primevue/image";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(PrimeVue);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);
// app.use(DialogService);

app.component("PrimeSlider", Slider);
app.component("InputNumber", InputNumber);
app.component("PrimeButton", Button);
app.component("InputGroup", InputGroup);
app.component("InputGroupAddon", InputGroupAddon);
app.component("InputSwitch", InputSwitch);
app.component("SplitButton", SplitButton);
app.component("RadioButton", RadioButton);
app.component("PrimeFieldset", Fieldset);
app.component("PrimeToolbar", Toolbar);
app.component("PrimeTextarea", Textarea);
app.component("ConfirmDialog", ConfirmDialog);
// app.component("DynamicDialog", DynamicDialog);
app.component("SelectButton", SelectButton);
app.component("ScrollPanel", ScrollPanel);
app.component("PrimeImage", Image);

app.mount("#app");
