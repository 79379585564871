<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      class="st0"
      d="M4,6.6C4,6,4.5,5.5,5.1,5.5h9c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1h-0.4v3.4c0,0.4,0.3,0.8,0.8,0.8h3.4
	c4.8,0,8.6,3.9,8.6,8.6v3.8h0.4c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1h-9c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1h0.4v-3.4
	c0-0.4-0.3-0.8-0.8-0.8h-3.4c-4.8,0-8.6-3.9-8.6-8.6V7.8H5.1C4.5,7.8,4,7.2,4,6.6 M20.5,24.2h3.8v-3.8c0-3.5-2.9-6.4-6.4-6.4l0,0
	h-0.8v3.8h0.4c1.7,0,3,1.3,3,3V24.2z M7.8,7.8h3.8v3.4c0,1.7,1.3,3,3,3h0.4v3.8h-0.8c-3.5,0-6.4-2.9-6.4-6.4V7.8z"
    />
  </svg>
</template>
<script setup></script>
