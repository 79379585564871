<template>
  <PrimeToolbar>
    <template #start>
      <ObjectInputNumeric
        label="Высота потолка:"
        v-model="ceiling"
        v-if="stage == 1"
      />
    </template>
    <template #center>
      <div class="toolbar-actions" v-if="stage == 1">
        <PrimeButton
          :label="`${placePoint ? 'Добавьте' : 'Добавить'} точку`"
          @click="placePoint = !placePoint"
        />
        <PrimeButton label="Добавить проём" @click="addOpening('window')" />
        <!-- <SplitButton
          :model="openingOptions"
          label="Добавить проем"
          class="action-btn"
        /> -->
      </div>
      <div class="toolbar-actions" v-if="stage == 2">
        <PrimeButton
          label="Добавить коммуникации"
          @click="addCommunications('electricity')"
        />
        <!-- <SplitButton
          :model="communicationsOptions"
          label="Инженерные коммуникации"
          class="action-btn"
        /> -->
      </div>
    </template>
    <template #end>
      <ConfirmDialog></ConfirmDialog>
      <PrimeButton
        v-if="stage == 1"
        label="Следующий этап"
        icon="pi pi-arrow-right"
        iconPos="right"
        @click="confirmMoveToNextStage()"
      />
      <PrimeButton
        v-if="stage == 2"
        label="Предыдущий этап"
        icon="pi pi-arrow-left"
        iconPos="left"
        @click="confirmMoveToPreviousStage()"
      />
    </template>
  </PrimeToolbar>
  <div class="container">
    <div class="active-object">
      <ActiveObject></ActiveObject>
    </div>
    <canvas
      id="canvas"
      ref="canvasElRef"
      width="1000px"
      height="800px"
      style="border: 1px solid #dedede; border-radius: 6px"
    ></canvas>

    <MeasureInput v-for="wall in wallsRef" :key="wall.index" :wall="wall" />
    <MeasureDistanceInput
      v-model="activeObject.distanceToLeft"
      :isLeft="true"
    />
    <MeasureDistanceInput
      v-model="activeObject.distanceToRight"
      :isLeft="false"
    />
  </div>
  <PrimeToolbar>
    <template #start>
      <div class="toolbar-actions">
        <PrimeButton
          label="Собрать JSON"
          severity="secondary"
          @click="compileJSON"
        />
      </div>
    </template>
    <template #center>
      <PrimeTextarea
        v-model="compiledJSON"
        rows="4"
        cols="100"
        spellcheck="false"
      ></PrimeTextarea>
    </template>
    <template #end>
      <PrimeButton
        label="Загрузить из JSON"
        severity="info"
        @click="loadFromJSON()"
      />
    </template>
  </PrimeToolbar>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { nextTick, onMounted, ref, watch, computed } from "vue";
import { initCanvas } from "@/script/canvasUtils";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import { drawOrthMeasureLines, positionAllInputs } from "@/script/measureUtils";
import MeasureInput from "@/components/MeasureInput.vue";
import SplitButton from "primevue/splitbutton";
import ActiveObject from "@/components/ActiveObject.vue";
import MeasureDistanceInput from "./MeasureDistanceInput.vue";
import { addOpening, addCommunications } from "@/script/objectsUtils";
import ObjectInputNumeric from "./ObjectInputNumeric.vue";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const canvasDataStore = useCanvasDataStore();
const {
  wallsRef,
  placePoint,
  polygonRef,
  activeObject,
  compiledJSON,
  canvasElRef,
  ceiling,
  stage,
  canvasRef,
  openingsRef,
  communicationsRef,
  //placeCommunications,
} = storeToRefs(canvasDataStore);
const { compileJSON, loadFromJSON } = canvasDataStore;
onMounted(() => {
  initCanvas(canvasElRef.value);
  window.onresize = () => {
    positionAllInputs();
  };
});

////////НУЖНО ЧТОБЫ ИНПУТЫ СПОЗИЦИОНИРОВАЛИСЬ ПОСЛЕ ОТРИСОВКИ СПИСКА, А ТО ПЫТАЕМСЯ НАЙТИ ЭЛЕМЕНТ КОТОРЫЙ ЕЩЕ НЕ ОТРИСОВАН
watch(
  () => polygonRef.value.points,
  () => {
    nextTick(positionAllInputs);
  },
  { deep: true, once: true }
);
watch(stage, () => {
  nextTick(() => {
    canvasRef.value.calcOffset();
    positionAllInputs();
  });
});

const openingOptions = [
  {
    label: "Окно",
    command: () => {
      addOpening("window");
    },
  },
  {
    label: "Дверь",
    command: () => {
      addOpening("door");
    },
  },
  {
    label: "Арку",
    command: () => {
      addOpening("arch");
    },
  },
];

const communicationsOptions = [
  {
    label: "Газ",
    command: () => {
      //placeCommunications.value = "gas";
      addCommunications("gas");
    },
  },
  {
    label: "Вода",
    command: () => {
      //placeCommunications.value = "water";
      addCommunications("water");
    },
  },
  {
    label: "Электричество",
    command: () => {
      //placeCommunications.value = "electricity";
      addCommunications("electricity");
    },
  },
  {
    label: "Вентиляция",
    command: () => {
      //placeCommunications.value = "airduct";
      addCommunications("airduct");
    },
  },
];
const confirmMoveToNextStage = () => {
  confirm.require({
    message:
      "Вы уверены, что хотите перейти к следующему этапу? \nПланировка и параметры проемов будут недоступны для изменения. \nНеприкрепленные проемы будут удалены.",
    header: "Подтверждение",
    icon: "pi pi-question-circle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Отменить",
    acceptLabel: "Cледующий этап",
    accept: () => {
      handleStageChange(2);
    },
    reject: () => {},
  });
};
const confirmMoveToPreviousStage = () => {
  confirm.require({
    message: "Вы уверены, что хотите перейти к предыдущему этапу?",
    header: "Подтверждение",
    icon: "pi pi-question-circle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Отменить",
    acceptLabel: "Предыдущий этап",
    accept: () => {
      handleStageChange(1);
    },
    reject: () => {},
  });
};
function handleStageChange(moveToStage) {
  stage.value = moveToStage;
  const canChange = moveToStage == 1;
  const openings = openingsRef.value;
  polygonRef.value.set({ selectable: canChange, hasControls: canChange });
  placePoint.value = false;
  openings.forEach((opening) => {
    if (opening.attachedToWall == undefined) {
      canvasRef.value.remove(opening);
    }
    opening.set({
      lockMovementX: !canChange,
      lockMovementY: !canChange,
      selectable: canChange,
      evented: canChange,
    });
  });
  openingsRef.value = openingsRef.value.filter(
    (opening) => opening.attachedToWall !== undefined
  );
  wallsRef.value.forEach((wall) => {
    wall.set({ selectable: canChange });
  });

  communicationsRef.value.forEach((com) => {
    com.set({
      selectable: !canChange,
      hoverCursor: !canChange ? "move" : "default",
      lockMovementX: canChange,
      lockMovementY: canChange,
      evented: !canChange,
    });
  });

  drawOrthMeasureLines();
  activeObject.value = {};
  canvasRef.value.renderAll();
}
</script>

<style>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap-reverse;
  margin-bottom: 2rem;
}
.p-toolbar {
  margin-bottom: 2rem;
}
.toolbar-actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.active-object {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 400px;
}
.action-btn {
  align-self: start;
}

.nowAdding-text {
  margin: 0;
}
.p-dialog {
  max-width: 400px;
}
</style>
