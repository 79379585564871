/* eslint-disable no-unused-vars */
import { fabric } from "fabric";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import { line, point, segment } from "@flatten-js/core";
import { getObjectSizeWithStroke } from "@/script/polygonUtils";

function getNextIndexInLoop(currentIndex, length) {
  if (length == undefined)
    console.log("getNextIndexInLoop: length is undefined");
  return currentIndex == length - 1 ? 0 : currentIndex + 1;
}
function getPrevIndexInLoop(currentIndex, length) {
  if (length == undefined)
    console.log("getNextIndexInLoop: length is undefined");
  return currentIndex == 0 ? length - 1 : currentIndex - 1;
}

//wallIndex - индекс стены,
//mouseEvent - мышечное событие
//функция опускает проекцию на полигонную стенку из положения мыши.
//нужна для размещения и движения проемов
function getMouseProjectionOnWall(wallIndex, mouseEvent, projectOnMain = true) {
  const canvasDataStore = useCanvasDataStore();
  const { polygonRef, innerPolygon } = storeToRefs(canvasDataStore);
  const polygon = projectOnMain ? polygonRef.value : innerPolygon.value;
  const { x, y } = transformPointerToPolygonCoords(mouseEvent);
  const AB = getWallFlattenLine(wallIndex, polygon.points);
  const pointC = point(x, y);
  const projection = pointC.projectionOn(AB);
  return projection;
}

//wallIndex - индекс стены,
//points - из каких точек создаем отрезок/линию
//returnSegment - показатель того, нужна линия или отрезок
//функция нужна для получения линии или отрезка с помощью библиотеки flatten
function getWallFlattenLine(wallIndex, points, returnSegment = false) {
  const thisPoint = points[wallIndex],
    nextPoint = points[getNextIndexInLoop(wallIndex, points.length)];

  let pointA = point(thisPoint.x, thisPoint.y),
    pointB = point(nextPoint.x, nextPoint.y);
  if (pointA.equalTo(pointB)) {
    //нельзя чтобы точки были идентичны, сделаем маленькое изменение. потом слипнется
    pointA.x++;
  }
  return returnSegment ? segment(pointA, pointB) : line(pointA, pointB);
}

function calcDistance(x1, y1, x2, y2) {
  return Math.round(Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)) || 0;
}
function getWallCoords(wallIndex, points) {
  const nextPoint = points[getNextIndexInLoop(wallIndex, points.length)];
  return {
    x1: points[wallIndex].x,
    y1: points[wallIndex].y,
    x2: nextPoint.x,
    y2: nextPoint.y,
  };
}

//преобразуем clientX, clientY в координаты, соответсвующие полигону
function transformPointerToPolygonCoords(mouseEvent) {
  const canvasDataStore = useCanvasDataStore(),
    { polygonRef, canvasRef } = storeToRefs(canvasDataStore),
    { x, y } = canvasRef.value.getPointer(mouseEvent),
    polygon = polygonRef.value,
    mouseLocalPosition = polygon.toLocalPoint(
      new fabric.Point(x, y),
      "center",
      "center"
    );
  const polygonBaseSize = getObjectSizeWithStroke(polygon),
    size = polygon._getTransformedDimensions(0, 0),
    xFinal =
      (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
      polygon.pathOffset.x,
    yFinal =
      (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
      polygon.pathOffset.y;
  return { x: xFinal, y: yFinal };
}

//функция возвращает точку на заданной прямой на заданном расстоянии от начала
function pointAtLength(x1, y1, x2, y2, len) {
  const dx = x2 - x1,
    dy = y2 - y1;
  const theta = Math.atan2(dy, dx);
  const xp = len * Math.cos(theta);
  const yp = len * Math.sin(theta);
  const res = { x: xp + x1, y: yp + y1 };
  return res;
}

export {
  pointAtLength,
  transformPointerToPolygonCoords,
  getWallCoords,
  calcDistance,
  getWallFlattenLine,
  getMouseProjectionOnWall,
  getPrevIndexInLoop,
  getNextIndexInLoop,
};
