<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      d="M21.1,8.4l-0.6,0.7C20,9.7,19.3,10,18.6,10C17.3,10,16,9,16,7.5V3.3c0,0-10.1,5.1-10.1,13.9c0,5.6,4.5,10.1,10.1,10.1
	s10.1-4.5,10.1-10.1C26.1,13.5,24.1,10.1,21.1,8.4 M16,24.8c-1.4,0-2.5-1.1-2.5-2.5c0-0.6,0.3-1.3,0.7-1.7l1.8-1.8l1.8,1.8
	c0.5,0.5,0.7,1.1,0.7,1.7C18.5,23.7,17.4,24.8,16,24.8 M21,22.9c0.1-0.5,0.3-2.4-1.4-4.1L16,15.3l-3.6,3.5c-1.7,1.7-1.5,3.6-1.4,4.1
	c-1.6-1.4-2.6-3.5-2.6-5.7c0-4,2.7-7.1,5.1-9.2c0.3,2.6,2.5,4.5,5,4.5c1,0,1.9-0.3,2.8-0.8c1.5,1.5,2.3,3.4,2.3,5.5
	C23.6,19.5,22.6,21.5,21,22.9"
    />
  </svg>
</template>
<script setup></script>
