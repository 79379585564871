<template>
  <InputGroup
    :style="`transform:rotate(${activeObject.inputAngle}deg)`"
    :id="idString.group"
    :class="{
      slim: !showButtons,
      active: showButtons,
      hidden: hidden,
    }"
  >
    <InputNumber
      v-model="modelValue"
      :inputId="idString.input"
      suffix=" мм"
      @focus="activateInput"
      @blur="delayBlur"
      @update:modelValue="changeDistance(isLeft)"
      inputClass="measureInput"
      :disabled="stage == 2 && activeObject.openingType"
    />

    <PrimeButton
      v-show="showButtons"
      :icon="`pi pi-check`"
      @click="changeDistance(isLeft)"
    />
  </InputGroup>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { defineProps, toRefs, ref } from "vue";
// import { changeWallLength } from "@/script/wallsUtils";
// import { positionInput, getMeasureLine } from "@/script/measureUtils";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";
import {
  calcDistance,
  getWallFlattenLine,
  pointAtLength,
} from "@/script/generalUtils";
import { point } from "@flatten-js/core";
import { fabric } from "fabric";
import { initScale } from "@/script/canvasUtils";
import { computed, defineModel } from "vue";
import { drawObjectMeasures, positionAllInputs } from "@/script/measureUtils";
import { updateIconPos } from "@/script/objectsUtils";

const modelValue = defineModel();
const canvasDataStore = useCanvasDataStore();
const { activeObject, polygonRef, innerPolygon, stage } =
  storeToRefs(canvasDataStore);

const props = defineProps(["isLeft"]);
const { isLeft } = toRefs(props);
const showButtons = ref(false);

const idString = computed(() => {
  return {
    group: `measureInputGroup${isLeft.value ? "Left" : "Right"}`,
    input: `measureInput${isLeft.value ? "Left" : "Right"}`,
  };
});
const hidden = computed(() => {
  const res =
    !activeObject.value ||
    !activeObject.value[`show${isLeft.value ? "Left" : "Right"}Input`];
  return res;
});
const isOpening = computed(() => activeObject.value.openingType !== undefined);

function activateInput() {
  showButtons.value = true;

  setTimeout(() => {
    positionAllInputs();
  }, 5);
}
function delayBlur() {
  setTimeout(() => {
    showButtons.value = false;
    setTimeout(() => {
      positionAllInputs();
    }, 5);
  }, 200);
}
function changeDistance(toLeft) {
  const isOp = isOpening.value;
  const polygon = isOp ? polygonRef.value : innerPolygon.value;

  const points = polygon.points;
  const innerPoints = innerPolygon.value.points;
  const opening = activeObject.value;
  if (opening.attachedToWall === undefined) return;
  //взять стенку к которой прилеплен проем, ее координаты
  //сделать из нее сегмент
  let innerSegment = getWallFlattenLine(
    opening.attachedToWall,
    innerPoints,
    true
  );

  //если расстояние до левой стены, то разворачиваем отрезок
  if (!toLeft) innerSegment = innerSegment.reverse();
  //откладываем pointAtLength
  const newPoint = pointAtLength(
    innerSegment.ps.x,
    innerSegment.ps.y,
    innerSegment.pe.x,
    innerSegment.pe.y,
    toLeft
      ? activeObject.value.distanceToLeft + opening.widthClean / 2
      : activeObject.value.distanceToRight + opening.widthClean / 2
  );
  //проецируем на стенку
  const outerLine = getWallFlattenLine(opening.attachedToWall, points);
  const projection = point(newPoint.x, newPoint.y).projectionOn(outerLine);
  //записываем в объект, меняем top & left, отрисовываем
  const matrix = polygon.calcTransformMatrix();
  const transformedPoint = fabric.util.transformPoint(
    {
      x: projection.x,
      y: projection.y,
    },
    matrix
  );

  const otherDistance =
    calcDistance(newPoint.x, newPoint.y, innerSegment.pe.x, innerSegment.pe.y) -
    opening.widthClean / 2;
  const distanceToLeft = toLeft
    ? activeObject.value.distanceToLeft
    : otherDistance;
  const distanceToRight = !toLeft
    ? activeObject.value.distanceToRight
    : otherDistance;
  opening.set({
    top: transformedPoint.y - polygon.pathOffset.y * initScale,
    left: transformedPoint.x - polygon.pathOffset.x * initScale,
    distanceToLeft,
    distanceToRight,
    showLeftInput: distanceToLeft > 0,
    showRightInput: distanceToRight > 0,
  });
  if (!isOp) {
    updateIconPos(opening);
  }

  activeObject.value[toLeft ? "distanceToRight" : "distanceToLeft"] =
    otherDistance;
  drawObjectMeasures(opening);
}
</script>
