/* eslint-disable no-unused-vars */
// import { getWallLineByIndex } from "@/script/canvasUtils";
import {
  getNextIndexInLoop,
  calcDistance,
  getWallFlattenLine,
  //getWallCoords,
  pointAtLength,
  getWallCoords,
  //getPrevIndexInLoop,
} from "@/script/generalUtils";
import { checkOrthogonal, makeWalls } from "@/script/wallsUtils";
import { defineStore } from "pinia";
import { ref } from "vue";
import { fabric } from "fabric";
import { simplifyPolygon } from "@/script/polygonUtils";
import offsetPolygon from "offset-polygon";
//import { drawOrthMeasureLines } from "@/script/measureUtils";
import { initPolygon, initScale } from "@/script/canvasUtils";
import { makeOpening, makeComMarker } from "@/script/objectsUtils";
import { point } from "@flatten-js/core";
export const useCanvasDataStore = defineStore("canvasDataStore", () => {
  const initPoints = ref([
    {
      x: 0,
      y: 0,
    },
    {
      x: 4200,
      y: 0,
    },
    {
      x: 4200,
      y: 3200,
    },
    {
      x: 3200,
      y: 3200,
    },
    {
      x: 3200,
      y: 4200,
    },
    {
      x: 0,
      y: 4200,
    },
  ]);
  const canvasElRef = ref(null);
  //тут хранится информация о фабриковой canvas, чтобы везде иметь доступ к ее методам
  const canvasRef = ref({});
  //тут хранится информация о фабриковом объекте Polygon, который мы тянем за уголки
  const polygonRef = ref({ points: [] });
  //тут хранится информация о внутреннем полигоне, который являет собой внутренний периметр комнаты.
  //За счет него считаем все размеры.
  const innerPolygon = ref({});
  const innerPolygon2 = ref({});
  const innerPolygon3 = ref({});
  //тут хранятся фабриковые объекты Line, за счет которых делаем выделение стен и перетягивание стены по рельсам
  const wallsRef = ref([
    { wallIndex: 0, length: 3000 },
    { wallIndex: 1, length: 3000 },
    { wallIndex: 2, length: 3000 },
    { wallIndex: 3, length: 3000 },
  ]);
  //тут информация о том, что мы сейчас добавляем точку
  const placePoint = ref(false);
  //тут хранится информация о проемах:о фабрикове объекты group
  const openingsRef = ref([]);
  // информация о размещенных коммуникациях
  const communicationsRef = ref([]);
  //тут информация о выбранном объекте - ссылка на один из элементов из openingsRef/communicationsRef
  const activeObject = ref({});
  //тут хранится оффсет полигона (пол-ширины и пол-высоты) на момент создания стен (wallsRef).
  //этот оффсет используется при движении стен, чтобы они оставались наложенными на стенки основного полигона
  const initPathOffset = ref(0);
  //тут толщина стенок. Не стала делать реф, тк нет необходимости менять
  const wallThickness = 100;
  const ceiling = ref(2600);

  const compiledJSON = ref("");
  //информация о текущей стадии планирования: 1-планировка (стены, проемы),
  //2 - размещение коммуникаций
  const stage = ref(1);
  function getWallLength(wallIndex) {
    //инофрмацию о точках берем не из wallsRef, потому что там может еще не быть этой стены
    const innerPoints = innerPolygon.value.points;
    let startPoint = innerPoints[wallIndex];
    const nextIndex = getNextIndexInLoop(wallIndex, innerPoints.length);
    let endPoint = innerPolygon.value.points[nextIndex];
    // if (isNaN(startPoint.x) && isNaN(endPoint.x)) {
    //   startPoint = innerPoints[getPrevIndexInLoop(wallIndex)];
    //   endPoint = innerPoints[getNextIndexInLoop(nextIndex, innerPoints.length)];
    // } else if (isNaN(endPoint.x)) {
    //   endPoint = startPoint;
    //   startPoint = innerPoints[getPrevIndexInLoop(wallIndex)];
    // }
    const res = calcDistance(
      startPoint.x,
      startPoint.y,
      endPoint.x,
      endPoint.y
    );
    return res;
  }

  function updateWallData(wallIndexes, showMeasureLine = false) {
    wallIndexes.forEach((wallIndex) => {
      const orthogonality = checkOrthogonal(wallIndex);
      const slope = fabric.util.radiansToDegrees(
        getWallFlattenLine(wallIndex, polygonRef.value.points).slope
      );
      let reverse = false;
      let buttonsVertical = false;
      let slopeMod = slope;
      if (slope >= 90 && slope < 270) {
        slopeMod -= 180;
        //если мы перевернули инпут для стены, то и кнопки будут в другую сторону смотреть чем раньше.
        //например, стрелка влево раньше смотрела на начальную точку, а теперь на конечную
        //добавлять мм будем с другой стороны получается
        reverse = true;
      }
      slopeMod -= slopeMod >= 270 ? 360 : 0; //это чтобы анимация была не кручение с 270 до 0, а с -90 до 0

      if (slopeMod <= -45 || slopeMod >= 45) {
        //если стена наклонена больше чем на 45 градусов, кнопки будем показывать наверх и вниз
        buttonsVertical = true;
      }
      const wall = wallsRef.value[wallIndex];

      wall.set({
        length: getWallLength(wallIndex),
        isHorizontal: orthogonality.isHorizontal,
        isVertical: orthogonality.isVertical,
        slope: slope,
        slopeMod: slopeMod,
        hasMeasureLine: showMeasureLine,
        showInput: showMeasureLine,
        inputId: "measureInputGroup" + wallIndex,
        reverse: reverse,
        buttonsVertical: buttonsVertical,
      });
    });
  }

  function compileJSON() {
    simplifyPolygon();
    const innerPoints = innerPolygon.value.points;

    const openingsAttached = openingsRef.value.filter(
      (op) => op.attachedToWall !== undefined
    );
    const communicationsAttached = communicationsRef.value.filter(
      (com) => com.attachedToWall !== undefined
    );
    const walls = innerPoints.map((point, index, arr) => {
      const start = [point.x, -1 * point.y];
      const nextPoint = arr[getNextIndexInLoop(index, arr.length)];
      const end = [nextPoint.x, -1 * nextPoint.y];
      const openings = openingsAttached
        .filter((op) => op.attachedToWall === index)
        .map((opening) => {
          const coords = pointAtLength(
            start[0],
            start[1],
            end[0],
            end[1],
            opening.distanceToLeft
          );
          return {
            id: opening.id,
            name: opening.openingType,
            attachedToWall: opening.attachedToWall,
            single: opening.single,
            opensInside: opening.opensInside,
            opensToRight: opening.opensToRight,
            frameType: opening.frameType,
            distanceToLeft: opening.distanceToLeft,
            size: [opening.widthClean, opening.openingHeight],
            position: [coords.x, coords.y, opening.distanceFromFloor],
          };
        });
      const communications = communicationsAttached
        .filter((com) => com.attachedToWall === index)
        .map((com) => {
          const coords = pointAtLength(
            start[0],
            start[1],
            end[0],
            end[1],
            com.distanceToLeft
          );
          return {
            id: com.id,
            name: com.communicationType,
            attachedToWall: com.attachedToWall,
            distanceToLeft: com.distanceToLeft,
            position: [coords.x, coords.y, com.distanceFromFloor],
          };
        });
      return { start, end, openings, communications };
    });

    const compiledInfo = { ceiling: ceiling.value, walls };
    compiledJSON.value = JSON.stringify(compiledInfo);
  }
  function loadFromJSON() {
    if (!compiledJSON.value) return;
    try {
      const data = JSON.parse(compiledJSON.value);
      const innerPolyPoints = data.walls.map((wall) => {
        return { x: wall.start[0], y: -1 * wall.start[1] };
      });
      //innerPolygon.value.points = innerPolyPoints;
      initPoints.value = offsetPolygon(innerPolyPoints, wallThickness / 2, 0);
      reset();
      ceiling.value = data.ceiling;
      initPolygon();

      data.walls.forEach((wall) => {
        wall.openings.forEach((opening) => {
          recreateOpening(opening);
        });
        wall.communications.forEach((com) => {
          recreateCommunication(com);
        });
      });
    } catch (e) {
      console.log("Oшибка: некорректный формат входящих данных", e);
    }
  }

  function reset() {
    canvasRef.value.clear();
    canvasRef.value.renderAll();

    wallsRef.value = [];
    openingsRef.value = [];
    activeObject.value = {};
    placePoint.value = false;
    communicationsRef.value = [];
  }

  function recreateOpening(opening) {
    const polygon = polygonRef.value;
    const wall = wallsRef.value[opening.attachedToWall];
    const innerWallCoords = getWallCoords(
      opening.attachedToWall,
      innerPolygon.value.points
    );
    const width = opening.size[0];
    const centerCoords = pointAtLength(
      innerWallCoords.x1,
      innerWallCoords.y1,
      innerWallCoords.x2,
      innerWallCoords.y2,
      opening.distanceToLeft + width / 2
    );
    const AB = getWallFlattenLine(opening.attachedToWall, polygon.points);
    const projection = point(centerCoords.x, centerCoords.y).projectionOn(AB);

    const polygonM = polygon.calcTransformMatrix();
    const transformedPoint = fabric.util.transformPoint(projection, polygonM);
    const left = transformedPoint.x - polygon.pathOffset.x * initScale;
    const top = transformedPoint.y - polygon.pathOffset.y * initScale;
    const openingObj = makeOpening({
      widthClean: width,
      angle: wall.slope,
      id: opening.id,
      left,
      top,
      openingType: opening.name,
      single: opening.single,
      opensInside: opening.opensInside,
      opensToRight: opening.opensToRight,
      frameType: opening.frameType,
      inputAngle: wall.slopeMod,
      distanceFromFloor: opening.position[2],
      buttonsVertical: false, //wall.buttonsVertical,
      showLeftInput: false,
      showRightInput: false,
      openingHeight: opening.size[1],
      distanceToLeft: opening.distanceToLeft,
      distanceToRight: wall.length - width - opening.distanceToLeft,
      attachedToWall: opening.attachedToWall,
    });
    canvasRef.value.add(openingObj);
    openingsRef.value.push(openingObj);
  }

  function recreateCommunication(com) {
    const innerPoly = innerPolygon.value;
    //const wall = wallsRef.value[com.attachedToWall];
    const innerWallCoords = getWallCoords(
      com.attachedToWall,
      innerPolygon.value.points
    );
    //const width = opening.size[0];
    const centerCoords = pointAtLength(
      innerWallCoords.x1,
      innerWallCoords.y1,
      innerWallCoords.x2,
      innerWallCoords.y2,
      com.distanceToLeft
    );
    const AB = getWallFlattenLine(com.attachedToWall, innerPoly.points);
    const projection = point(centerCoords.x, centerCoords.y).projectionOn(AB);

    const polygonM = innerPoly.calcTransformMatrix();
    const transformedPoint = fabric.util.transformPoint(projection, polygonM);
    const left = transformedPoint.x - innerPoly.pathOffset.x * initScale;
    const top = transformedPoint.y - innerPoly.pathOffset.y * initScale;
    makeComMarker({
      communicationType: com.name,
      distanceToLeft: com.distanceToLeft,
      top,
      left,
      attachedToWall: com.attachedToWall,
      id: com.id,
      distanceFromFloor: com.position[2],
    });
  }
  return {
    initPoints,
    canvasElRef,
    canvasRef,
    polygonRef,
    wallsRef,
    initPathOffset,
    wallThickness,
    ceiling,
    openingsRef,
    compiledJSON,
    innerPolygon,
    innerPolygon2,
    innerPolygon3,
    placePoint,
    activeObject,
    stage,
    //placeCommunications,
    communicationsRef,
    getWallLength,
    updateWallData,
    compileJSON,
    loadFromJSON,
  };
});
