<template>
  <InputGroup
    :style="`transform:rotate(${wall.slopeMod}deg)`"
    :id="'measureInputGroup' + wall.wallIndex"
    :class="{
      slim: !showButtons,
      active: showButtons,
      hidden: !(wall.showInput && wall.hasMeasureLine) || wall.length == 0,
    }"
  >
    <PrimeButton
      v-show="showButtons"
      :icon="`pi pi-arrow-${wall.buttonsVertical ? 'down' : 'left'}`"
      @click="changeWallLength(wall.wallIndex, wall.length, true)"
    />

    <InputNumber
      v-model="wall.length"
      :inputId="'measureInput' + wall.wallIndex"
      suffix=" мм"
      inputClass="measureInput"
      @focus="activateInput"
      @blur="delayBlur"
      :disabled="stage == 2"
    />

    <PrimeButton
      v-show="showButtons"
      :icon="`pi pi-arrow-${wall.buttonsVertical ? 'up' : 'right'}`"
      @click="changeWallLength(wall.wallIndex, wall.length, false)"
    />
  </InputGroup>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import { defineProps, toRefs, ref } from "vue";
import { changeWallLength } from "@/script/wallsUtils";
import { positionInput, getMeasureLine } from "@/script/measureUtils";
import { useCanvasDataStore } from "@/stores/canvasDataStore";
import { storeToRefs } from "pinia";

const { stage } = storeToRefs(useCanvasDataStore());
const showButtons = ref(false);
const props = defineProps(["wall"]);
const { wall } = toRefs(props);

function activateInput() {
  showButtons.value = true; // && stage.value == 1;

  setTimeout(() => {
    positionInput(getMeasureLine(wall.value.wallIndex));
  }, 5);
}
function delayBlur() {
  setTimeout(() => {
    showButtons.value = false;
    setTimeout(() => {
      const measure = getMeasureLine(wall.value.wallIndex);
      if (measure) {
        positionInput(measure);
      }
    }, 5);
  }, 200);
}
</script>

<style>
.p-button.p-button-icon-only {
  width: unset;
  padding: 0.4rem 0.4rem;
}

.measureInput {
  text-align: center;
  padding: 0.5rem 0.5rem;
}

.p-inputgroup {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 140px;
  transition: transform 0.1s ease-in;
}

.slim {
  max-width: 90px;
  padding: 0;
}

.slim .p-inputtext {
  border: none;
  max-width: 70px;
  box-shadow: none;
  background-color: rgba(255, 255, 255);
  font-size: 0.8rem;
  padding: 0;
  border-radius: 0;
}
.active .p-inputtext {
  font-size: 0.8rem;
}
.p-inputtext:disabled {
  color: black;
}

.active {
  z-index: 5;
  transform: rotate(0deg) !important;
}

.hidden {
  visibility: hidden;
}
</style>
